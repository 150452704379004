import React, {useEffect} from "react";
import phone from "./phone.svg";
import logo from "./shield.svg";
import photo from "./photo.png";
import map from "./map.png";
import Less from "../../modules/less/less";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


function Unit() {
    return (
        <div>
            <svg width="695" height="980" fill="#000">
                <polygon points="0 0,0 983, 695 983, 695 0" fill="#91e3df"/>
                <polygon points="312 13, 134 42, 348 133" fill="#2a96de"/>
                <polygon points="0 0, 145 0, 0 75" fill="#268ede"/>
                <polygon points="0 75, 0 190, 150 0" fill="#5acce0"/>
                <polygon points="0 235, 185 350, 0 360" fill="#4bc3db"/>
                <polygon points="0 360, 185 350, 0 470" fill="#58cae0"/>
                <polygon points="190 0, 190 10, 312 13, 370 0" fill="#39b7d4"/>
                <polygon points="305 0, 312 13, 330 0" fill="#2892de"/>
                <polygon points="312 13, 348 133, 490 0, 370 0" fill="#4ec8de"/>
                <polygon points="490 0, 420 60, 630 0" fill="#73d7de"/>
                <polygon points="630 0, 430 50, 470 90, 680 0" fill="#8cdedb"/>
                <polygon points="695 825, 0 825, 0 450, 695 450" id="green"/>
                <polygon points="0 825, 695 825, 695 983, 0 983" fill="#0070a1"/>
                <polygon points="330 810, 695 810, 695 983, 420 983" fill="#000000"/>
                <polygon points="0 811, 60 811, 0 870" fill="#aeff00" stroke="#000000" strokeWidth="3"/>
                <polygon points="0 620, 0 440, 695 440, 432 594" fill="#7edede"/>
                <circle cx="480" cy="307" r="264" fill="#00b3ff"/>
                <image x="0" y="189" height="432" width="325" xlinkHref={photo}/>
                <polygon points="50 0, 193 0, 193 180, 50 180" fill="#fff"/>
                <text className="edit bg" id="when" textAnchor="end" y="395" x="690"/>
                <text className="edit bg" id="time" textAnchor="end" y="450" x="690"/>
                <text className="edit bg" id="where" textAnchor="end" y="502" x="690"/>
                <text className="edit bg" id="address" textAnchor="end" y="550" x="690"/>
                <text className="edit bg" id="lozung" transform="rotate(-4)" y="625" x="-10"/>
                <image x="300" y="565" width="500" height="350" xlinkHref={map}/>

                <polygon points="330 810, 695 810, 695 983, 420 983" fill="#000000"/>

                <foreignObject y="655" x="28" width="391" height="160" fontWeight="bold">
                    <h1 className="edit" id="h1"/>
                </foreignObject>

                <foreignObject y="770" x="331" width="260" height="160" fontWeight="bold">
                    <p className="edit" id="maintext">Марьинский бульвар, 10</p>
                </foreignObject>

                <foreignObject y="835" x="47" width="264" height="160" fontWeight="bold">
                    <p className="edit" id="contactus"/>
                    <img alt="" src={phone} style={{float: "left"}}/>
                    <span className="edit" id="phone"/>
                </foreignObject>
                <foreignObject y="835" x="401" width="264" height="160" fontWeight="bold">
                    <p className="edit" id="visitsite"/>
                    <span className="edit" id="site"/>
                </foreignObject>

                <text className="edit" id="collage" y="200" x="283"/>
                <text className="edit" id="fair" y="336" x="303"/>
                <image xlinkHref={logo} height="115" y="17" x="73"/>
                <text className="edit" id="logotext" y="160" x="58"/>
            </svg>
        </div>
    )
}


function FlyerUnit() {
    useEffect( () => {
        (async ()=>{
            const data = (await firebase.firestore().collection('flyer').doc('data').get()).data();

            const elsss = document.getElementsByClassName("edit");

            for (let i = 0; i < elsss.length; i++) {
                const el = elsss[i];
                el.innerHTML = el.id && data[el.id] ? data[el.id] : "#" + el.id;
                el.addEventListener("click", () => {
                    const t = el.innerHTML.trim();
                    const text = prompt("Введите", t);
                    el.innerHTML = text ? text : el.innerHTML;
                    if (el.id) data[el.id] = el.innerHTML;

                    firebase.firestore().collection('flyer').doc('data').set(data);
                })
            }

            const py = 20;
            const pl = 10;
            const pr = 5;


            const svgs = document.getElementsByTagName("svg");
            for (let i = 0; i < svgs.length; i++) {
                const svg = svgs[i];
                const els = svg.getElementsByClassName("bg");
                for (let i = 0; i < els.length; i++) {
                    els[i].setAttribute("x", els[i].getAttribute("x") - pr);
                    const b = els[i].getBBox();
                    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                    rect.setAttribute("x", b.x - pl);
                    rect.setAttribute("y", b.y - py / 2);
                    rect.setAttribute("width", 550);//b.width + pl + pr);
                    rect.setAttribute("height", b.height + py);
                    rect.setAttribute("fill", "#02729e");
                    els[i].getAttribute("transform") && rect.setAttribute("transform", els[i].getAttribute("transform"));
                    svg.insertBefore(rect, els[i]);
                }
            }
        })()


    }, [])

    return (
        <Less file={require("./flyer.less")}>
            <div style={{display: "flex", zoom: 0.4}}>
                <div>
                    <Unit/>
                    <Unit/>
                </div>
                <div style={{transform: "rotate(90deg)", display: "flex", marginLeft: -989, marginTop: 114}}>
                    <Unit/>
                    <Unit/>
                    <Unit/>
                </div>
            </div>

        </Less>
    )
}

export default function Flyermap() {
    return (
        <div style={{marginLeft: 6, marginTop: 4}}>
            <FlyerUnit/>
            <div style={{pageBreakBefore: "always"}}/>
            <Unit/>
        </div>
    )
}