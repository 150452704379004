import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {StudentView} from "./view";
import {Avatar} from "../firebase/data";

function CustomTabPanel({ children, value, index }) {
    return (
        <div>
            {value === index && children}
        </div>
    );
}



export const BasicTabs = ({students}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    {students.map((student, i)=>(
                        <Tab label={(
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Avatar size={40} stretchSize={100} url={student.photo}/>
                                {student.name}
                            </div>
                        )} key={i}/>
                    ))}
                </Tabs>
            </Box>
            {students.map((student, i)=>(
                <CustomTabPanel key={i} value={value} index={i}>
                    <StudentView data={student}/>
                </CustomTabPanel>
            ))}
        </Box>
    );
}