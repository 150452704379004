import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Provider} from "react-redux";
import {RTKStore} from "./redux/rtk";

export const firebaseConfig = {
    apiKey: "AIzaSyAt3ARQ6wDJXTkmf1waPHYIPFwImGCxJsA",
    authDomain: "future-simple-club.firebaseapp.com",
    databaseURL: "https://future-simple-club.firebaseio.com",
    projectId: "future-simple-club",
    storageBucket: "future-simple-club.appspot.com",
    messagingSenderId: "663800857165",
    appId: "1:663800857165:web:958f4748b37ac16ba59204",
    measurementId: "G-H6R4BZ5TP3"
}

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED, merge: true});
//firebase.firestore().enablePersistence().catch(() => {});

const theme = createTheme({
    palette: {
        primary: {
            main: "#3f51b5",
        },
        secondary: {
            main: "#f50057",
        },
    },
});


const root = createRoot(document.getElementById("root"));
root.render(
    <Provider store={RTKStore}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>
);

