import React, {useEffect, useState} from "react";
import Table from "../../modules/table/table";
import {onLoadedAll} from "../../firebase/data";


function ImplementsItems({id}) {
    const [implementGroups, setImplementGroups] = useState(false);

    useEffect(() => {
        onLoadedAll(["implementGroups", "implements"]).onUpdate(({implementGroups, implements: implements1}) => {
            implementGroups.forEach(implementGroup => implementGroup.count = implements1.filter(implement => implement.implementGroup === implementGroup._id).length);
            setImplementGroups(implementGroups);
        })
    }, []);


    if (!implementGroups) return;


    return (
        <>

            <div style={{margin: "30px 0"}}>
                {implementGroups.map(implementGroup => implementGroup._id && (
                    <div key={implementGroup._id}>
                        <a style={{color: implementGroup._id === id ? "red" : "blue"}} href={`/admin/implements/${implementGroup._id}/`}>
                            {implementGroup.name} ({implementGroup.count})
                        </a>
                    </div>
                ))}
            </div>


            <Table template={{
                collection: "implements",
                title: "Имплементации",
                items: [
                    {title: "Группа", key: "implementGroup", editor: "hidden", db: id},
                    {title: "Название", key: "name", editor: "input"},
                    {title: "<nobr>Кол-во</nobr>", key: "quantity", editor: "input", small: true},
                    {title: "<nobr>Цена 1 шт</nobr>", key: "price", editor: "input", small: true},
                    {title: "Фото", key: "photo", editor: "image"},
                ]
            }}/>
        </>
    )

}


export default function Implements({match: {params: {id}}}) {
    return (
        <>
            <Table template={{
                collection: "implementGroups",
                title: "Группы имплементаций",
                items: [
                    {title: "Название", key: "name", editor: "input"},
                ]
            }}/>

            <ImplementsItems id={id}/>
        </>
    )

}