import React from "react";

export default function Less({file, children}) {
    const [css, setCSS] = React.useState("");
    fetch(file).then(res => res.text()).then(setCSS);
    return (
        <React.Fragment>
            <style>{css}</style>
            {children}
        </React.Fragment>
    )
}