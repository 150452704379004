import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Container, IconButton, TextField, Typography} from '@mui/material';
import Link from "@mui/material/Link";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneIcon from "@mui/icons-material/Phone";
import Logo from "../logo/logo";
import {TelegramBotSendMessage} from "../telegrambot/telegrambot";


const sendWelcomeDetails = () => {
    const date = new Date();
    const url = new URL(window.location.href);
    const width = window.innerWidth;
    const height = window.innerHeight;
    const path = url.pathname.split("/").slice(2,-1).join("/");
    const params = url.search && url.search.substr(1).split("&").map(c => c.split("=")).reduce((s, [k, v]) => ({...s, [k]: v}), {});
    const userAgent = navigator.userAgent;
    const referrer = window.document.referrer;
    const welcome = {_date: +date, path, params, userAgent, referrer, width, height, date};
    TelegramBotSendMessage(`QR-код ${path} ${date.toLocaleString().replace(",", "")}\n${userAgent} ${width}x${height} ${params} ${referrer}`);

    return new Promise((res) => firebase.firestore().collection("welcomeDetails").add(welcome).then(res))
}

export const Welcome = () => {

    useEffect(()=>{
        sendWelcomeDetails();
    }, [])


    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [progress, setProgress] = useState("start");

    const handleNameChange = (event) => setName(event.target.value);
    const handleContactChange = (event) => setContact(event.target.value);

    const handleSubmit = (event) => {
        const url = document.location.href;
        const date = new Date();

        event.preventDefault();
        setProgress("sending");
        TelegramBotSendMessage(`Новая заявка ${date.toLocaleString().replace(",", "")}\n${name}\n${contact}\n${url}`);
        firebase.firestore()
            .collection("orders")
            .add({name, phone: contact, url, date, _date: +date})
            .then(() => setProgress("success"))
            .catch(() => setProgress("error"))
    };


    const school = {
        "address": "Москва, Марьинский бульвар, 10",
        "yandex": "https://yandex.ru/maps/-/C-Wezla",
        "phone": "+7 495 142-91-57",
        "email": "info@futuresimple.club",
        "whatsapp": "https://chat.whatsapp.com/LNtQsHGoKJZDbvhDRaYCVB",
        "telegram": "https://t.me/FutureSimpleSchool",
        "vk": "https://vk.com/futuresimpleclub",
    }

    const {whatsapp, telegram, phone, yandex, address} = school;


    return (
        <Container maxWidth="sm" style={{marginTop: 10}}>
            <a href={"/"}>
            <Logo/>
            </a>

            <Link target="_blank" href={yandex}>
                <address style={{textAlign: "right"}}>{address}</address>
            </Link>
            <hr/>
            <Typography variant="h3" style={{marginBottom: 10, marginTop: 20}}>
                Запишитесь на бесплатный урок:
            </Typography>
            <div style={{display: "flex", alignItems: "center"}}>
                <Link underline="none" href={`tel:${phone.replace(/[^0-9+]/gi, "")}`}>
                    <Button style={{whiteSpace: "nowrap"}} color="secondary"  variant="contained" startIcon={<PhoneIcon/>}>
                        {phone}
                    </Button>
                </Link>
                <div style={{marginLeft: 30}}>
                    <Link underline="none" target="_blank" href={whatsapp}>
                        <WhatsAppIcon title="Написать в WhatsApp" style={{color: "#25D366", fontSize: 40}}/>
                    </Link>
                    <Link underline="none" target="_blank" href={telegram}>
                        <TelegramIcon title="Написать в Telegram" style={{color: "#0088CC", fontSize: 40}}/>

                    </Link>
                </div>
            </div>
            <hr style={{margin: "30px 0"}}/>

            {progress === "start" && <form onSubmit={handleSubmit}>
                <Typography variant="h5" gutterBottom>Или оставьте свои контакты:</Typography>
                <TextField
                        label="Имя"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                    />
                    <TextField
                        label="Телефон"
                        variant="outlined"
                        fullWidth
                        value={contact}
                        onChange={handleContactChange}
                        margin="normal"
                    />

                    <Button type="submit" variant="contained" color="secondary">
                        Отправить заявку
                    </Button>
                </form>}
            {progress === "sending" && <div style={{textAlign: "center"}}>
                <CircularProgress/>
            </div>}
            {progress === "success" && <div>
                <Typography variant="h5" gutterBottom style={{color: "green"}}>
                    Спасибо за заявку!
                </Typography>
                <p>
                    Мы скоро Вам перезвоним
                </p>
            </div>}
            {progress === "error" && <div>
                <Typography variant="h5" gutterBottom style={{color: "red"}}>
                    К сожалению, что-то пошло не так
                </Typography>
                    <p>
                        <small>Пожалуйста, свяжитесь с нами по телефону: </small>
                        <a href={`tel:${phone.replace(/[^0-9+]/gi, "")}`}>
                            <nobr>{phone}</nobr>
                        </a>
                    </p>
            </div>}
        </Container>
    );
};




