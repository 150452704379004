import React, {useEffect, useState} from "react";
import {getData} from "../firebase/data";
import {TeacherView} from "./view";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";

export const Teacher = () => {
    const {email, role} = useSelector(state => state.user);
    const [data, setData] = useState({});

    useEffect(() => getData(email, setData, role), []);

    if (!data.ready) return <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/></div>;

    return (
        <TeacherView teacher={data.teacher} schedules={data.schedules}/>
    )
}



