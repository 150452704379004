import Grid from "@mui/material/Grid";
import React from "react";
import Card from "@mui/material/Card/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DoneIcon from '@mui/icons-material/Done';
import List from "@mui/material/List";
import CardActions from "@mui/material/CardActions";
import CallToAction from "../calltoaction/calltoaction";

export default function Cards({cards}) {
    return (
        <Grid container spacing={5} className="section">
            {cards.map(({title, image, text, lines, url}) => (
                <Grid key={title} item xs={12} sm={6} md={4}>
                    <Card>
                        <Link href={url} underline="none" color="textPrimary">
                            <CardActionArea>
                                <CardMedia image={image} title={title} style={{height: 200}}/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h3">{title}</Typography>
                                    <small>{text}</small>
                                    <List>
                                        {lines.map(t => (
                                                <ListItem key={t}>
                                                    <ListItemIcon style={{minWidth: 35}}><DoneIcon color="primary"/></ListItemIcon>
                                                    <ListItemText className="price-rouble" >
                                                        <div dangerouslySetInnerHTML={{__html: t}}/>
                                                    </ListItemText>
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                        <CardActions>
                            <CallToAction size="medium" fullWidth/>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}
