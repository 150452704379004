import React from "react";
import Table from "../../modules/table/table";


export default function Reschedulings() {
    return (
        <Table template={{
            collection: "reschedulings",
            title: "Переносы уроков",
            items: [
                {title: "Дата", key: "date", editor: "input"},
                {title: "Время", key: "time", editor: "input"},
                {title: "Учитель", key: "teacher", editor: "db", db: {collection: "teachers", title: "name"}},
                {title: "Группа", key: "group", editor: "db", db: {collection: "groups", title: "name"}},
                {title: "Кабинет", key: "cabinet", editor: "db", db: {collection: "cabinets", title: "name"}},
                {title: "Отмена/Назначение", key: "type", editor: "select", db: [{title: "-------", value: ""},{title: "Отмена", value: "cancel"},{title: "Назначение", value: "assign"}]},
            ]
        }}/>
    )

}