import React from "react";
import Header from "../../header/header";
import Footer from "../../footer/footer";
import Maps from "../../maps/maps";
import Container from "@mui/material/Container";
import Jumbotron from "../../jumbotron/jumbotron";
import bg from "../../../bg.png";
import Courses from "../../courses/courses";
import Postscript from "../../scripts/postscript";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Course from "../../course/course";
import Link from "@mui/material/Link";



export default function Home({match: {params: {schoolId, courseId}}}) {
    const [settings, setSettings] = React.useState(false);
    const [schools, setSchools] = React.useState(false);
    const [courses, setCourses] = React.useState(false);

    !settings && firebase.firestore().collection("settings").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setSettings(list);
    });
    !schools && firebase.firestore().collection("schools").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setSchools(list);
    });
    !courses && firebase.firestore().collection("courses").where("public", "==", "true").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setCourses(list);
    });

    if (!settings || !schools || !courses) return <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/>
    </div>;

    const courseNorm = (school, course) => {
        const price = (school) => Math.round(+course.price + course.price / 100 * school.percent) + (+school.plus || 0);
        const max = schools.reduce((s, school) => Math.max(s, price(school)), 0);
        const min = schools.reduce((s, school) => Math.min(s, price(school)), max);

        let lines = [];
        for (const i in course) if (i.split("line")[1] > 0) lines.push(course[i]);

        const salePercent = 0;

        const priceText = salePercent ? (
            school ? `Стоимость <s>${price(school)}₽ в месяц</s> (${price(school)*(100-salePercent)/100}₽ до 01.09.2024)` : min === max ? `Стоимость <s>${min}₽ в месяц</s> (${min*(100-salePercent)/100}₽ до 01.09.2024)` : `Стоимость ${min}-${max}₽ в месяц (зависит от местонахождения школы)`
        ):(
            school ? `Стоимость ${price(school)}₽ в месяц` : min === max ? `Стоимость ${min}₽ в месяц` : `Стоимость ${min}-${max}₽ в месяц (зависит от местонахождения школы)`
        );

        return ({
            url: `${school ? `/school/${school.id}` : ""}/course/${course.id}/`,
            title: course.headline,
            lines: [...lines, priceText],
            text: course.tagline,
            image: course.image
        })
    };

    let {
        phone,
        email,
        whatsapp,
        telegram,
        vk,
        tiktok,
        instagram,
        facebook,
        youtube,
        headline,
        tagline,
        address,
        coords,
        schedules
    } = settings[0];

    let lines = [];
    for (const i in settings[0]) if (i.split("line")[1] > 0) lines.push(settings[0][i]);


    const course = courses.filter(course => course.id === courseId)[0];
    const school = schools.filter(school => school.id === schoolId)[0];


    if (school) {
        school.phone && (phone = school.phone);
        school.email && (email = school.email);
        school.whatsapp && (whatsapp = school.whatsapp);
        school.telegram && (telegram = school.telegram);
        school.vk && (vk = school.vk);
        school.tiktok && (tiktok = school.tiktok);
        school.instagram && (instagram = school.instagram);
        school.facebook && (facebook = school.facebook);
        school.youtube && (youtube = school.youtube);

        school.headline && (headline = school.headline);
        school.tagline && (tagline = school.tagline);
        school.address && (address = school.address);
        school.coords && (coords = school.coords);
        school.schedules && (schedules = school.schedules);
    }

    return (
        <div style={{background: `url(${bg})`}}>
            <Container>
                <div style={{display: "flex", justifyContent: "end", marginTop: 10}}>
                    <Link title="Личный кабинет" underline="none"  href={`/student/`}>Личный кабинет</Link>
                </div>
                <Header {...{address, coords, schedules, phone, email, whatsapp, telegram, vk, tiktok, instagram, facebook, youtube}}/>
                <Jumbotron headline={headline} tagline={tagline} lines={lines} phone={phone}/>
                <Maps schools={school ? [school] : schools} course={!school && course}/>
                {
                    course ? (
                        <Course course={courseNorm(school, course)}/>
                    ) : (
                        <Courses courses={courses.reduce((s, c) => s.concat(courseNorm(school, c)), [])}/>
                    )
                }
            </Container>
            <Footer/>
            <Postscript/>
        </div>
    )

}
