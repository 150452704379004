import React from "react";
import Header from "../../header/header";
import Footer from "../../footer/footer";
import Container from "@mui/material/Container";
import Jumbotron from "../../jumbotron/jumbotron";
import bg from "../../../bg.png";
import Courses from "../../courses/courses";
import Postscript from "../../scripts/postscript";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";


export default function Online({match: {params: {schoolId, courseId}}}) {
    const [settings, setSettings] = React.useState(false);
    const [schools, setSchools] = React.useState(false);
    const [courses, setCourses] = React.useState(false);

    !settings && firebase.firestore().collection("settings").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setSettings(list);
    });
    !schools && firebase.firestore().collection("schools").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setSchools(list);
    });
    !courses && firebase.firestore().collection("courses").where("public", "==", "true").get().catch((e) => console.log(e)).then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        setCourses(list);
    });

    if (!settings || !schools || !courses) return <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/>
    </div>;

    const courseNorm = (school, course) => {
        const price = (school) => Math.round(+course.price + course.price / 100 * school.percent) + (+school.plus || 0);
        const max = schools.reduce((s, school) => Math.max(s, price(school)), 0);
        const min = schools.reduce((s, school) => Math.min(s, price(school)), max);

        let lines = [];
        for (const i in course) if (i.split("line")[1] > 0) lines.push(course[i]);

        return ({
            //url: `${school ? `/school/${school.id}` : ""}/course/${course.id}/`,
            title: course.headline,
            lines: [...lines, school ? `Стоимость ${price(school)}₽ в месяц` : min === max ? `Стоимость ${min}₽ в месяц` : `Стоимость ${min}-${max}₽ в месяц (зависит от местонахождения школы)`],
            text: course.tagline,
            image: course.image
        })
    };

    let {phone, email, whatsapp, telegram, vk, tiktok, instagram, facebook, youtube, headline, tagline, address, coords, schedules, online} = settings[0];

    let lines = [];
    for (const i in settings[0]) if (i.split("line")[1] > 0) lines.push(settings[0][i]);


    const links = [];
    const images = [];

    online.files.forEach(({src, name}) => {
        const [n, type] = name.split(".");
        const isImage = ["JPG", "JPEG", "PNG", "GIF", "WEBP"].includes(type.toUpperCase());
        isImage ? images.push({src, name: n}) : links.push({src, name: n});
    })

    return (
        <div style={{background: `url(${bg})`}}>
            <Container>
                <Header {...{address, coords, schedules, phone, email, whatsapp, telegram, vk, tiktok, instagram, facebook, youtube}}/>
                <Jumbotron headline={headline} tagline={tagline} lines={lines} phone={phone}/>
                <Courses courses={courses.reduce((s, c) => s.concat(courseNorm(null, c)), [])}/>
            </Container>
            <div style={{margin: "0 20px"}}>
                <h3>Реквизиты</h3>
                <pre style={{margin: "20px 0"}}>{online.text}</pre>
                <div style={{display: "flex", alignItems: "center"}}>
                    {links.map(({src, name}) => <a style={{marginRight: 20}} target="_blank" href={src}>{name}</a>)}
                    <div style={{display: "flex"}}>{images.map(({src, name}) => <img src={src} alt={name}/>)}</div>
                </div>
            </div>
            <Footer/>
            <Postscript/>
        </div>
    )

}