import React from "react";


export default function Recommend() {
    return (
        <div style={{margin: 20}}>
            <style>
                h1 {`{font-size: 2em!important; text-align: left}`}
                h2 {`{font-size: 1.5em!important; text-align: left}`}
            </style>

            <h1>Программа «Приведи друга»</h1>

            <h2>Как приводить друзей в школу Future Simple и получать за это скидки</h2>
            <p>Если вы учитесь в школе Future Simple и вам хочется посоветовать школу своему другу, сделайте это с пользой для вас обоих — дайте ему промокод. Если друг им воспользуется, каждый из вас получит скидку.</p>
            <p>Друг получит скидку -2000₽, а Вы получите -1000₽ на оплату следующего месяца</p>

            <h2>Как получить скидки по программе «Приведи друга»</h2>
            <p>Чтобы вы вместе с другом получили скидки, вам нужно отправить другу промокод, а он должен поступить в школу Future Simple и оплатить за обучение.</p>

            <h2>Как пригласить друга</h2>
            <p>Отправьте промокод другу в любом мессенджере или продиктуйте по телефону. Промокод находится в личном кабинете на https://futuresimple.club/student/</p>

            <h2>Ограничения</h2>
            <p>Ограничений по количеству приглашенных друзей нет, их может быть и 2, и 10.</p>
            <p>Приглашенный друг еще не должен быть зарегистрирован в школе Future Simple</p>
        </div>
    )
}