import React, {useEffect} from "react";
import phone from "./phone.svg";
import logo from "./shield.svg";
import photo from "./china.jpg";
import qrCode from "../../qr-code.svg";
import Less from "../../modules/less/less";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


function Unit() {
    const width = 695;
    const height = 986;
    return (
        <div style={{width, height, border: "solid 1px #000"}}>
            <svg width={width} height={height} fill="#000">
                <polygon points={`0 0,0 ${height}, ${width} ${height}, ${width} 0`} fill={"#91e3df"}/>
                <polygon points={`312 13, 134 42, 348 133`} fill={"#2a96de"}/>
                <polygon points={`0 0, 145 0, 0 75`} fill={"#268ede"}/>
                <polygon points={`0 75, 0 190, 150 0`} fill={"#5acce0"}/>
                <polygon points={`0 235, 185 350, 0 360`} fill={"#4bc3db"}/>
                <polygon points={`0 360, 185 350, 0 470`} fill={"#58cae0"}/>
                <polygon points={`190 0, 190 10, 312 13, 370 0`} fill={"#39b7d4"}/>
                <polygon points={`305 0, 312 13, 330 0`} fill={"#2892de"}/>
                <polygon points={`312 13, 348 133, 490 0, 370 0`} fill={"#4ec8de"}/>
                <polygon points={`490 0, 420 60, 630 0`} fill={"#73d7de"}/>
                <polygon points={`630 0, 430 50, 470 90, 680 0`} fill={"#8cdedb"}/>
                <polygon points={`${width} 825, 0 825, 0 450, ${width} 450`} fill={"#517b04"}/>
                <polygon points={`0 825, ${width} 825, ${width} ${height}, 0 ${height}`} fill={"#0070a1"}/>
                <polygon points={`330 810, ${width} 810, ${width} ${height}, 420 ${height}`} fill={"#000000"}/>
                <polygon points={`0 811, 60 811, 0 870`} fill={"#aeff00"} stroke="#000000" strokeWidth="3"/>
                <polygon points={`0 620, 0 440, ${width} 440, 432 594`} fill={"#7edede"}/>
                <circle cx="480" cy="307" r="264" fill={"#ff0000"}/>
                <image x="10" y="189" height="432" width="280" xlinkHref={photo}/>
                <polygon points="50 0, 193 0, 193 180, 50 180" fill={"#ffffff"}/>
                <text className="edit bg" id="when2" textAnchor="end" y="405" x={width}/>
                <text className="edit bg" id="time2" textAnchor="end" y="450" x={width}/>
                <text className="edit bg" id="where2" textAnchor="end" y="500" x={width}/>
                <text className="edit bg" id="address2" textAnchor="end" y="550" x={width}/>
                <text className="edit bg" id="lozung2" transform="rotate(-4)" y="625" x="-10"/>
                <rect  x={430} y={565} width={500} height={245} fill={"#ffffff"}/>
                <image x={310} y={565} width={500} height={245} xlinkHref={qrCode}/>

                <polygon points={`330 810, ${width} 810, ${width} ${height}, 420 ${height}`} fill={"#000000"}/>
                <foreignObject y="655" x="28" width="391" height="100" fontWeight="bold"><h1 className="edit" id="h1"/></foreignObject>
                <foreignObject x={100} y={770} width={260} height={40} fontWeight="bold"><p className="edit" id="maintext">Марьинский бульвар, 10</p></foreignObject>
                <foreignObject y="835" x="47" width="264" height="100" fontWeight="bold"><p className="edit" id="contactus"/><img alt="" src={phone} style={{float: "left"}}/><span className="edit" id="phone"/></foreignObject>
                <foreignObject y="835" x="401" width="264" height="100" fontWeight="bold"><p className="edit" id="visitsite"/><span className="edit" id="site"/></foreignObject>
                <text className="edit" id="collage2" y="200" x="283"/>
                <text className="edit" id="fair2" y="336" x="303"/>
                <image xlinkHref={logo} height="115" y="17" x="73"/>
                <text className="edit" id="logotext" y="160" x="58"/>
            </svg>
        </div>
    )
}


function FlyerUnit() {

    useEffect( () => {
        (async ()=>{
            const data = (await firebase.firestore().collection('flyer').doc('data').get()).data();

            const elsss = document.getElementsByClassName("edit");

            for (let i = 0; i < elsss.length; i++) {
                const el = elsss[i];
                el.innerHTML = el.id && data[el.id] ? data[el.id] : "#" + el.id;
                el.addEventListener("click", () => {
                    const t = el.innerHTML.trim();
                    const text = prompt("Введите", t);
                    el.innerHTML = text ? text : el.innerHTML;
                    if (el.id) data[el.id] = el.innerHTML;

                    firebase.firestore().collection('flyer').doc('data').set(data);
                })
            }

            const py = 20;
            const pl = 10;
            const pr = 5;


            const svgs = document.getElementsByTagName("svg");
            for (let i = 0; i < svgs.length; i++) {
                const svg = svgs[i];
                const els = svg.getElementsByClassName("bg");
                for (let i = 0; i < els.length; i++) {
                    els[i].setAttribute("x", els[i].getAttribute("x") - pr);
                    const b = els[i].getBBox();
                    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                    rect.setAttribute("x", b.x - pl);
                    rect.setAttribute("y", b.y - py / 2);
                    rect.setAttribute("width", 550);//b.width + pl + pr);
                    rect.setAttribute("height", b.height + py);
                    rect.setAttribute("fill", "rgb(255,98,0)");
                    els[i].getAttribute("transform") && rect.setAttribute("transform", els[i].getAttribute("transform"));
                    svg.insertBefore(rect, els[i]);
                }
            }
        })()


    }, [])


    return (
        <Less file={require("./flyer.less")}>
            <Unit/>
        </Less>
    )
}

export default function FlyerChina() {
    return (
        <FlyerUnit/>
    )
}