import React from 'react';
import Typography from "@mui/material/Typography";
import logo from "./logo.png";

const apikey = "6c9d5c18-b6af-4b39-8a71-d9b85ae9d119";

export default class Maps extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.ready = this.ready.bind(this);
    }

    ready() {
        const {schools, course} = this.props;
        this.setState({ready: true});

        function appendLocation(location) {
            window.setCoords && window.setCoords(location.coords.latitude, location.coords.longitude);
        }

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(appendLocation, null, {enableHighAccuracy: true});
            navigator.geolocation.watchPosition(appendLocation, null, {enableHighAccuracy: true});
        }

        const ymaps = window.ymaps;

        ymaps.ready(() => {
            const map = new ymaps.Map("map", {center: [55.754541, 37.621017], zoom: 10, controls: ["zoomControl","searchControl", "rulerControl"]}, {searchControlProvider: "yandex#search"});

            schools.forEach(({id, phone, address, coords, schedules}) => {
                if(!coords)return;
                const placemark = new ymaps.Placemark(JSON.parse(coords), {
                    balloonContent: `<a href="/school/${id}/${course ? `course/${course.id}/` : ``}">${address}</a>`,
                    hintContent: address
                }, {
                    iconLayout: 'default#image',
                    iconImageHref: logo,
                    iconImageSize: [30, 30],
                    iconImageOffset: [-15, -15]
                });
                map.geoObjects.add(placemark);
            });

            map.setBounds(map.geoObjects.getBounds()).then(() => map.getZoom() > 16 && map.setZoom(16));
        });
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.onload = this.ready;
        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${apikey}&lang=ru_RU`;
        document.head.appendChild(script);
    }

    render() {
        return (
            <div className="section">
                <Typography gutterBottom variant="h2">Школы на карте</Typography>
                <div id="map" style={{height: "100%", width: "100%", minHeight: 500, borderRadius: 10}}/>
            </div>
        )
    }
}
