import React, {useEffect} from "react";
import Table from "../../modules/table/table";
import "firebase/compat/firestore";
import "./families.css";
import {onLoadedAll, studentBalance, Topic} from "../../firebase/data";


const List = (props) => {
    const [list, setList] = React.useState(false);

    useEffect(() => {
        onLoadedAll(["families", "parents", "students", "statuses", "incomes", "ratings", "studentGroups", "groups"])
            .onUpdate(({families, parents, students, studentGroups, statuses}) => {
                const list = families;
                statuses = statuses.filter(status => status.isActual).map(status => status._id);
                students = students.filter(student => statuses.includes(student.status));

                list.forEach(item => {
                    item.contracts = item.contracts || 0;
                    item.students = students.filter(s => s.family === item._id);
                    item.parents = parents.filter(s => s.family === item._id);
                    item.total = 0;
                    item.studying = 0;
                    item.paid = 0;
                    item.students.forEach(student => {
                        item.contracts += studentGroups.filter(sg => sg.student === student._id).length;
                        studentBalance(student._id, ({studying, paid, total}) => {
                            for(const i in total) item.total = item.total + total[i];
                            for(const i in total) item.studying = item.studying + studying[i];
                            for(const i in total) item.paid = item.paid + paid[i];
                        });
                    });
                });

                setList(list.filter(l => l.students.length));
            })
    }, []);

    if (!list) return false;

    return (
        <div>
            <a target="_blank" href="/admin/families-create/">Создать или редактировать</a>
            <hr/>
            <table style={{width: "1%", whiteSpace: "nowrap"}}>
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Договоры</th>
                    <th>Документы</th>
                    <th>Родителей</th>
                    <th>Студентов</th>
                    <th>Обучались</th>
                    <th>Оплачено</th>
                    <th>Баланс</th>
                </tr>
                </thead>
                <tbody>
                {
                    list.map((item) => (
                        <tr key={item._id}>
                            <td style={{}}>
                                <a style={{color: item._id === props._id ? "#ff0000" : "#000000"}}
                                   href={`/admin/families/${item._id}/`}>
                                    {item.name}
                                </a>
                            </td>
                            <td>{item.contracts}</td>
                            <td><Topic data={item.documents}/></td>
                            <td>{item.parents.length}</td>
                            <td>{item.students.length}</td>
                            <td>{item.studying}</td>
                            <td>{item.paid}</td>
                            <td>{item.total}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
};


export default function Families({match: {params: {id}}}) {
    return (
        <>
            <List _id={id}/>
            {id && (
                <>
                    <Table template={{
                        collection: "parents",
                        title: "Родители",
                        items: [
                            {title: "Семья", key: "family", editor: "hidden", db: id},
                            {title: "ФИО", key: "name", editor: "textarea"},
                            {title: "Комментарии", key: "comment", editor: "textarea"},
                            {title: "Пол", key: "sex", editor: "select",small: true, db: [{title: "-----", value: ""}, {title: "М", value: "male"}, {title: "Ж", value: "female"}]},
                            {title: "Паспорт", key: "passport", editor: "textarea"},
                            {title: "Адрес", key: "address", editor: "textarea"},
                            {title: "Телефон", key: "phone", editor: "input"},
                        ]
                    }}/>
                    <Table template={{
                        collection: "students",
                        title: "Студенты",
                        items: [
                            {title: "Семья", key: "family", editor: "hidden", db: id},
                            {title: "ФИО студента", key: "name", editor: "textarea"},
                            {title: "День&nbsp;рождения", key: "birthday", editor: "date", small: true},
                            {title: "Пол", key: "sex", editor: "select",small: true, db: [{title: "-----", value: ""}, {title: "М", value: "male"}, {title: "Ж", value: "female"}]},
                           // {title: "ФИО родителя", key: "parent", editor: "textarea"},
                            {title: "Адрес", key: "address", editor: "textarea"},
                            {title: "Телефон", key: "phone", editor: "input"},
                            {title: "Комментарии", key: "comment", editor: "textarea"},
                            {title: "Фото", key: "photo", editor: "image"},
                        ]
                    }}/>
                </>
            )}
        </>
    )

}