import React, {useEffect, useState} from "react";
import "firebase/compat/firestore";
import Table from "../modules/table/table";
import firebase from "firebase/compat/app";



export default function TeacherProgram({match: {params: {id}}}) {
    const [headline, setHeadline] = useState("");
    useEffect(()=>{
        firebase.firestore().collection("courses").doc(id).onSnapshot(snapshot => {
            setHeadline(snapshot.data().headline)
        })
    }, []);


    return (
        <React.Fragment>
            {id && <Table template={{
                collection: `programs`,
                title: headline,
                items: [
                    {title: "Курс", key: "course", editor: "hidden", db: id},
                    {title: "#", key: "num", editor: "num", small: true},
                    {title: "Тема", key: "topic", editor: "materials"},
                    {title: "Д/З", key: "homework", editor: "materials"},
                ]
            }}/>}
        </React.Fragment>
    )

}