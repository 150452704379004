import React from 'react';
import Dialog from "./dialog";
import Button from "@mui/material/Button";

const ephone = "+7 495 142-91-57";

export default function CallToAction({size, fullWidth}) {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <Button
                size={size}
                fullWidth={fullWidth !== undefined}
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}>
                Записаться на бесплатный урок
            </Button>
            <Dialog ephone={ephone} open={open} onClose={() => setOpen(false)}/>
        </React.Fragment>
    )
}

