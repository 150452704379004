import DialogMUI from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import CircularProgress from "@mui/material/CircularProgress";
import {TelegramBotSendMessage} from "../../telegrambot/telegrambot";



export default class Dialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.send = this.send.bind(this);
    }



    send() {
        this.setState({touched: true});

        const {name, email, phone} = this.state;
        const url = document.location.href;
        const date = new Date();
        if (name && phone && email) {
            this.setState({progress: true});
            this.timeout = setTimeout(() => this.setState({error: true, progress: false}), 10000);
            TelegramBotSendMessage(`Новая заявка ${date.toLocaleString().replace(",", "")}\n${name}\n${phone}\n${email}\n${url}`);
            firebase.firestore()
                .collection("orders")
                .add({name, phone, email, url, date, _date: +date})
                .then(() => this.setState({success: true, progress: false}))
                .catch(() => this.setState({error: true, progress: false}))
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    render() {
        const {open, onClose, ephone} = this.props;
        const {touched, success, progress, error, name, email, phone} = this.state;

        if (progress) return (
            <DialogMUI open={open}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h4" gutterBottom>Запись на бесплатный урок</Typography>
                </DialogTitle>
                <DialogContent style={{textAlign: "center"}}>
                    <CircularProgress disableShrink />
                    <p>Подождите, пожалуйста</p>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </DialogMUI>
        );

        if (success) return (
            <DialogMUI open={open} onClose={onClose}>
                <DialogTitle disableTypography={true}>
                    <Typography style={{marginRight: 40}} variant="h4" gutterBottom>Запись на бесплатный урок</Typography>
                    <IconButton style={{position: "absolute", right: 8, top: 8}} onClick={onClose}><CloseIcon/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h3" gutterBottom>Спасибо, что выбрали нас!</Typography>
                    <p>Скоро наши операторы с Вами свяжутся</p>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={onClose}>Закрыть</Button>
                </DialogActions>
            </DialogMUI>
        );

        if (error) return (
            <DialogMUI open={open} onClose={onClose}>
                <DialogTitle disableTypography={true}>
                    <Typography style={{marginRight: 40}} variant="h4" gutterBottom>Запись на бесплатный урок</Typography>
                    <IconButton style={{position: "absolute", right: 8, top: 8}} onClick={onClose}><CloseIcon/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h3" gutterBottom>К сожалению, что-то пошло не так</Typography>
                    <p>Пожалуйста, позвоните нам по номеру <a href={`tel:${ephone.replace(/[^0-9+]/gi, "")}`}>{ephone}</a></p>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={onClose}>Закрыть</Button>
                </DialogActions>
            </DialogMUI>
        );

        return (
            <DialogMUI open={open} onClose={onClose}>
                <DialogTitle disableTypography={true}>
                    <Typography style={{marginRight: 40}} variant="h4" gutterBottom>Запись на бесплатный урок</Typography>
                    <IconButton style={{position: "absolute", right: 8, top: 8}} onClick={onClose}><CloseIcon/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Ваши личные данные никогда никому не передадим! Используем только для связи. &nbsp;<Link target="_blank" href="/privacy/" style={{color: "#0000008a", fontSize: 13}}>Политика конфиденциальности</Link></DialogContentText>
                    <TextField error={touched && !name} autoFocus margin="dense" label="Имя" type="text" fullWidth onChange={e => this.setState({name: e.target.value})}/>
                    <TextField error={touched && !phone} margin="dense" label="Телефон" type="telephone" fullWidth onChange={e => this.setState({phone: e.target.value})}/>
                    <TextField error={touched && !email} margin="dense" label="Email" type="email" fullWidth onChange={e => this.setState({email: e.target.value})}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"outlined"}>Отмена</Button>
                    <Button color="secondary" variant="contained" onClick={this.send}>Отправить</Button>
                </DialogActions>
            </DialogMUI>
        )
    }


}