import React from "react";
import Table from "../../modules/table/table";
import firebase from "firebase/compat/app";


const List = (props) => {
    const [list, setList] = React.useState(false);

    !list && firebase.firestore().collection("schools").get().then(snapshot => {
        const list = snapshot.docs.map(d => d.data());
        list.sort((a, b) => (a._date || 100000) - (b._date || 100000));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        firebase.firestore().collection("cabinets").get().catch(d=>console.log(d)).then(snapshot => {
            const cabinets = snapshot.docs.map(d => d.data());
            list.forEach(l=>{
                l.count = cabinets.filter(c=>c.school===l._id).length
            })
            setList(list);
        })
    });

    if(!list)return false;

    return (
        <ul>
            {
                list.map(({_id, address, count})=>(
                    <li key={_id}>
                        <span >{count}</span> &nbsp;
                        <a style={{color: _id === props.id ? "#ff0000" : "#000000"}} href={`/admin/cabinet/${_id}/`}>
                             {address}
                        </a>
                    </li>
                ))
            }
        </ul>
    )
};


export default function Cabinet({match: {params: {id}}}) {
    return (
        <>
            <List id={id}/>
            {id && <Table template={{
                collection: "cabinets",
                title: "Кабинеты",
                items: [
                    {title: "Кабинет", key: "name", editor: "input"},
                    {title: "Школа", key: "school", editor: "hidden", db: id},
                ]
            }}/>}
        </>
    )

}