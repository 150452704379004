import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {colors, IconButton} from "@mui/material";
import {CheckCircle, Close} from "@mui/icons-material";

const beforeInstallPrompt = (cb)=> {
    const beforeinstallprompt = (e) => {
        e.preventDefault();
        window.deferredPrompt = e;
        if (!cb) window.removeEventListener("beforeinstallprompt", beforeinstallprompt);
        cb && cb()
    }
    window.addEventListener("beforeinstallprompt", beforeinstallprompt);
    cb && cb()
}

beforeInstallPrompt();

const Install = ()=>{
    const [installing, setInstalling] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        beforeInstallPrompt(async ()=>{
            if (!window.deferredPrompt) return;

            setOpen(true);
            setInstalling(false);

            const {outcome} = await window.deferredPrompt.userChoice;

            if (outcome === "accepted") {
                setOpen(false)
            } else {
                setInstalling(false)
            }


        });

    }, [])


    const installHandler = () => {
        if (!installing) {
            setInstalling(true);
            window.deferredPrompt.prompt();
        }
    };

    return (
        <div className="Install">
            <style>
                {`@media print {.Install {display: none}}`}
            </style>
            <Grid container style={{
                display: open ? "flex" : "none",
                lineHeight: 0,
                cursor: "pointer",
                color: colors.common.white,
                backgroundColor: colors.green[600],
                position: "fixed",
                width: "100%",
                bottom: 0
            }}>
                <Grid item xs container justifyContent="center" alignItems="center" spacing={1} onClick={installHandler}>
                    <Grid item><CheckCircle/></Grid>
                    <Grid item>{installing ? "Идёт установка..." : "Установить приложение"}</Grid>
                </Grid>
                <Grid item>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <Close/>
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default Install;








