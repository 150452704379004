import React from "react";
import Table from "../../modules/table/table";


export default function Discounts() {
    return (
        <Table template={{
            collection: "discounts",
            title: "Скидки",
            items: [
                {title: "Дата от", key: "start", editor: "date"},
                {title: "Дата до", key: "end", editor: "date"},
                {title: "Скидка, %", key: "value", editor: "input"},
            ]
        }}/>
    )

}