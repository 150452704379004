import "./teacher.css";
import {CircularProgress, Rating} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Avatar, Dates, Topic} from "../firebase/data";


const Journal = ({date, time, group, cabinet, teacher}) => {
    const [loading, setLoading] = useState(true);
    const [docId, setDocId] = useState("");
    const ref = firebase.firestore().collection("journal");

    const toggle = () => {
        setLoading(true);
        if (docId) {
            ref.doc(docId).delete()
        } else {
            ref.add({date, time, group, cabinet, teacher})
        }
    }

    useEffect(() => {
        ref.where("date", "==", date)
            .where("time", "==", time)
            .where("group", "==", group)
            .where("cabinet", "==", cabinet)
            .where("teacher", "==", teacher).onSnapshot((snapshot) => {
            !snapshot.empty ? snapshot.forEach(doc => setDocId(doc.id)) : setDocId("");
            setLoading(false);
        });
    }, [date, time, group, cabinet, teacher]);

    if (loading) return <CircularProgress style={{width: 20, height: 20}}/>

    return (
        <input checked={!!docId} type="checkbox" onChange={toggle}/>
    )
}
const Student = ({data: {date, time, cabinet, teacher, group, student}}) => {
    const [loading, setLoading] = useState(true);
    const [docId, setDocId] = useState("");
    const [rate, setRate] = useState(0);
    const [comment, setComment] = useState("");
    const [present, setPresent] = useState(false);

    const ref = firebase.firestore().collection("ratings");

    useEffect(() => {
        ref
            .where("student", "==", student._id)
            .where("date", "==", date)
            .where("time", "==", time)
            .onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                        setDocId(doc.id);
                        setRate(+doc.get("rate"));
                        setPresent(doc.get("present"));
                        setComment(doc.get("comment"));
                    })
                } else {
                    setDocId("");
                    setRate(0);
                    setComment("");
                    setPresent(false);
                }
                setLoading(false);
            });
    }, []);

    const updatePresent = e => (v => present !== v && update(v, v ? rate : 0, comment))(e.target.checked);
    const updateRate = e => (v => rate !== v && update(present, v, comment))(+e.target.value);
    const updateComment = e => (v => comment !== v && update(present, rate, v))(e.target.value);


    const update = (present, rate, comment) => {
        setLoading(true);
        if (docId) {
            ref.doc(docId).update({present, rate, comment})
        } else {
            ref.add({date, time, group, cabinet: cabinet._id, teacher, present, rate, comment, student: student._id})
        }
    }


    return (
        <>
            <div style={{position: "absolute"}}>{loading && <CircularProgress/>}</div>
        <tr>
            <td><Avatar stretchSize={200} size={40} url={student.photo}/></td>
            <td>{student.name}</td>
            <td><input checked={present} type={"checkbox"} onChange={updatePresent}/></td>
            <td>
                <Rating value={rate} max={10} onChange={updateRate}/>
                <textarea placeholder={"Комментарии"} value={comment} style={{width: "100%"}} onChange={updateComment}/>
            </td>
        </tr>
        </>
    )
}
const Students = ({title, date, time, group, cabinet, teacher, students}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button onClick={handleClickOpen} style={{whiteSpace: "nowrap"}}>{title}</Button>
            <Dialog open={open} onClose={handleClose} scroll={"paper"}>
                <DialogTitle>
                    {title}
                    <div style={{fontSize: 12}}>{date} {time} {cabinet.name}</div>
                </DialogTitle>
                <DialogContent dividers={false}>
                    <table border={1} cellPadding={4} style={{borderCollapse: "collapse"}}>
                        <thead>
                        <tr>
                            <th>Фото</th>
                            <th>Студент</th>
                            <th>Присутствие</th>
                            <th>Оценка</th>
                        </tr>
                        </thead>
                        <tbody>
                        {students.map((student, i) => (
                            <Student data={{date, time, cabinet, teacher, group, student}} key={i}/>
                        ))}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const Balance = ({groups, teacher}) => {
    const [journal, setJournal] = useState(false);
    const [payments, setPayments] = useState(0);

    useEffect(() => {
        firebase.firestore().collection("journal")
            .where("teacher", "==", teacher._id)
            .onSnapshot((snapshot) => {
                const list = snapshot.docs.map(doc => doc.data())
                    .filter((item, i, list) => i === list.findIndex(l => `${l.date} ${l.time}` === `${item.date} ${item.time}`))
                    .reduce((s, c) => ({...s, [c.group]: (s[c.group] || []).concat(c)}), {})
                setJournal(list);
            });

        firebase.firestore().collection("payments")
            .where("teacher", "==", teacher._id).onSnapshot((snapshot) => {
            setPayments(snapshot.docs.map(doc => doc.data()).reduce((s, c) => s + (+c.quantity), 0))
        });


    }, []);


    if (!journal) return <CircularProgress/>

    const total = Object.entries(journal).reduce((s, c) => s + c[1].length, 0);


    return (
        <div>
            <hr/>
            <table border={1} cellPadding={4} style={{borderCollapse: "collapse"}}>
                <thead>
                <tr>
                    <th>Группа</th>
                    <th>Курс</th>
                    <th>Проведено</th>
                    <th>Telegram</th>
                </tr>
                </thead>
                <tbody>
                {groups.map((group, i) => {
                    return (
                        <tr key={i}>
                            <td>{group.name}</td>
                            <td><a target={"_blank"}
                                   href={`/teacher/program/${group.courseData._id}/`}>{group.courseData.headline}</a>
                            </td>
                            <td>{(journal[group._id] || []).length} / {group.programData.length}</td>
                            <td>{group.telegram && <a target="_blank" href={group.telegram}>Telegram</a>}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <hr/>
            <table border={1} cellPadding={4} style={{borderCollapse: "collapse"}}>
                <thead>
                <tr>
                    <th>Проведено уроков</th>
                    <th>Выплачено за уроки</th>
                    <th>Баланс</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{total}</td>
                    <td>{payments}</td>
                    <td>{total - payments}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}


export const TeacherView = ({teacher, schedules}) => {
    const {history, groups} = teacher;
    const [events, setEvents] = useState([]);
    const [date, setDate] = useState("");


    return (
        <div>
            <fieldset>
                <legend>Учитель</legend>
                <Avatar size={100} stretchSize={400} url={teacher.photo}/>
                <h4>{teacher.name}</h4>
                <button onClick={() => firebase.auth().signOut()}>Выйти</button>
                <hr/>
                <h4>Расписание</h4>
                <table border={1} style={{borderCollapse: "collapse"}}>
                    <thead>
                    <tr>
                        <th>Время</th>
                        <th>ПН</th>
                        <th>ВТ</th>
                        <th>СР</th>
                        <th>ЧТ</th>
                        <th>ПТ</th>
                        <th>СБ</th>
                        <th>ВС</th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules.map((schedule, i)=>{
                        return schedule.timeExists && (
                            <tr key={i}>
                                <td>{schedule.time}</td>
                                {[1, 2, 3, 4, 5, 6, 7].map(i => <td key={i}>{schedule[`g${i}`]}</td>)}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Balance groups={groups} teacher={teacher}/>


            </fieldset>
            <Dates history={history} setEvents={setEvents} setDate={setDate}/>
            <div style={{margin: 2}}>
                <table className={"history"}>
                    <thead>
                    <tr>
                        <th/>
                        <th>Время</th>
                        <th>Группа</th>
                        <th>Кабинет</th>
                        <th>Урок</th>
                        <th>Д/З</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events.map(({time, group, cabinet, topic, homework}, j) => (
                        <tr key={j}>
                            <td>
                                <Journal
                                    date={date}
                                    time={time}
                                    teacher={teacher._id}
                                    group={group._id}
                                    cabinet={cabinet._id}
                                />
                            </td>
                            <td>{time}</td>
                            <td>
                                <Students
                                    date={date}
                                    time={time}
                                    cabinet={cabinet}
                                    title={group.name}
                                    group={group._id}
                                    teacher={teacher._id}
                                    students={group.studentsData}
                                />
                            </td>
                            <td><nobr>{cabinet.name}</nobr></td>
                            <td className="top"><Topic data={topic}/></td>
                            <td className="top"><Topic data={homework}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
