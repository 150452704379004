import Grid from "@mui/material/Grid";
import Logo from "../../logo/logo";
import Button from "@mui/material/Button";
import React from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import Link from "@mui/material/Link";
import {IconButton} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';

export const VKIcon = () => {
    return (
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M15.07 2H8.93C3.33 2 2 3.33 2 8.93V15.07C2 20.67 3.33 22 8.93 22H15.07C20.67 22 22 20.67 22 15.07V8.93C22 3.33 20.67 2 15.07 2M18.15 16.27H16.69C16.14 16.27 15.97 15.82 15 14.83C14.12 14 13.74 13.88 13.53 13.88C13.24 13.88 13.15 13.96 13.15 14.38V15.69C13.15 16.04 13.04 16.26 12.11 16.26C10.57 16.26 8.86 15.32 7.66 13.59C5.85 11.05 5.36 9.13 5.36 8.75C5.36 8.54 5.43 8.34 5.85 8.34H7.32C7.69 8.34 7.83 8.5 7.97 8.9C8.69 11 9.89 12.8 10.38 12.8C10.57 12.8 10.65 12.71 10.65 12.25V10.1C10.6 9.12 10.07 9.03 10.07 8.68C10.07 8.5 10.21 8.34 10.44 8.34H12.73C13.04 8.34 13.15 8.5 13.15 8.88V11.77C13.15 12.08 13.28 12.19 13.38 12.19C13.56 12.19 13.72 12.08 14.05 11.74C15.1 10.57 15.85 8.76 15.85 8.76C15.95 8.55 16.11 8.35 16.5 8.35H17.93C18.37 8.35 18.47 8.58 18.37 8.89C18.19 9.74 16.41 12.25 16.43 12.25C16.27 12.5 16.21 12.61 16.43 12.9C16.58 13.11 17.09 13.55 17.43 13.94C18.05 14.65 18.53 15.24 18.66 15.65C18.77 16.06 18.57 16.27 18.15 16.27Z"/>
        </svg>
    )
}
const TiktokIcon = () => {
    return (
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 50 50" aria-hidden="true">
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
        </svg>
    )
}

export default function Header({address, coords, schedules, phone, email, whatsapp, telegram, vk, tiktok, instagram, facebook, youtube}) {
    return (
        <>
            <Grid container spacing={2} justify="space-between" style={{padding: "20px 0"}}>
                <Grid item xs={12} sm={6}>
                    <Link href="/">
                        <Logo/>
                    </Link>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={2} alignContent="flex-end">
                        <Grid item xs>
                            <div style={{textAlign: "end"}}>
                                {email && <Link underline="none" target="_blank" href={`mailto:${email}`}><IconButton style={{padding: 8}} title="Написать Email" variant="contained" color="primary"><EmailIcon/></IconButton></Link>}
                                {whatsapp && <Link underline="none" target="_blank" href={whatsapp.includes("http") ? whatsapp : `https://wa.me/${whatsapp}`}><IconButton style={{padding: 8}} title="Написать в WhatsApp" variant="contained" color="primary"><WhatsAppIcon/></IconButton></Link>}
                                {telegram && <Link underline="none" target="_blank" href={telegram.includes("http") ? telegram : `https://telegram.me/share/url?url=${telegram}`}><IconButton style={{padding: 8}} title="Написать в Telegram" variant="contained" color="primary"><TelegramIcon/></IconButton></Link>}
                                {vk && <Link underline="none" target="_blank" href={vk}><IconButton style={{padding: 8}} title="VK" variant="contained" color="primary"><VKIcon/></IconButton></Link>}
                                {tiktok && <Link underline="none" target="_blank" href={tiktok}><IconButton style={{padding: 8}} title="Tiktok" variant="contained" color="primary"><TiktokIcon/></IconButton></Link>}
                                {instagram && <Link underline="none" target="_blank" href={instagram}><IconButton style={{padding: 8}} title="Instagram" variant="contained" color="primary"><InstagramIcon/></IconButton></Link>}
                                {facebook && <Link underline="none" target="_blank" href={facebook}><IconButton style={{padding: 8}} title="Facebook" variant="contained" color="primary"><FacebookIcon/></IconButton></Link>}
                                {youtube && <Link underline="none" target="_blank" href={youtube}><IconButton style={{padding: 8}} title="Youtube" variant="contained" color="primary"><YouTubeIcon/></IconButton></Link>}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4} style={{marginBottom: 15, marginRight: 8}}>
                            <div style={{textAlign: "end"}}>
                                <Link underline="none" href={`tel:${phone.replace(/[^0-9+]/gi, "")}`}>
                                    <Button style={{whiteSpace: "nowrap"}} color="secondary" size="large" variant="contained" startIcon={<PhoneIcon/>}>
                                        {phone}
                                    </Button>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                    <Link target="_blank" href={`https://yandex.ru/maps/?rtext=~${coords}&rtt=pd`}>
                        <address style={{textAlign: "right"}}>{address}</address>
                    </Link>
                    <p style={{textAlign: "right"}}><small>{schedules}</small></p>
                </Grid>
            </Grid>
        </>
    )

}