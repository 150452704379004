import Logo from "../../logo/logo";
import React from "react";
import Roboto from "../../Roboto-Bold.ttf";
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import PlaceIcon from '@mui/icons-material/Place';
import TodayIcon from '@mui/icons-material/Today';
import QrCode from "../../qr-code.svg";


export default class Face extends React.Component{
    constructor(props) {
        super(props);
        this.state = {width: 200, height: 25, text: "Школа английского языка", fontFamily: "Roboto"};
    }

    componentDidMount() {
        (async ()=>{
            const font = new FontFace(this.state.fontFamily, `url("${Roboto}")`);
            await font.load();
            document.fonts.add(font);
        })()
    }

    render() {
        const s = {fontSize: 20, display: "flex", marginTop: 10};
        const d = {color: "grey", marginRight: 4};

        return (
            <>
                <div style={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}>
                    <Logo width={"90%"}/>
                    <hr style={{width: "100%"}}/>
                    <table>
                        <tbody>
                        <tr>
                            <td><img alt={""} width={200} src={QrCode}/></td>
                            <td style={{  paddingLeft: 20}}>
                                <div style={s}><PhoneIcon style={d}/>+7 495 142-91-57</div>
                                <div style={s}><LanguageIcon style={d}/>FutureSimple.club</div>
                                <div style={s}><PlaceIcon style={d}/>Марьинский бульвар, 10</div>
                                <div style={s}><TodayIcon style={d}/>Понедельник — суббота: с 10:00 до 20:00. Воскресенье — выходной.</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }





}
