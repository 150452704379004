import React, {useEffect, useState} from "react";
import Table from "../../modules/table/table";
import {onLoadedAll} from "../../firebase/data";


export default function Students({match: {params: {id}}}) {
    const [statuses, setStatuses] = useState(false);
    const [students, setStudents] = useState(false);

    useEffect(() => {
        onLoadedAll(["statuses", "students"]).onUpdate(({statuses, students}) => {
            setStatuses(statuses);
            setStudents(students);
        })
    }, []);


    if (!statuses) return;

    statuses.forEach(status => status.count = students.filter(student => student.status === status._id).length);
    const wostatus = students.filter(student => !student.status || student.status === "-------------").length;
    return (
        <>

            <div style={{margin: "30px 0", display: "flex", justifyContent: "space-between"}}>
                <div>
                    <a style={{color: !id ? "red" : "blue"}} href={`/admin/students/`}>
                        ------------- ({wostatus})
                    </a>
                </div>
                {statuses.map(status => (
                    <div key={status._id}>
                        <a style={{color: status._id === id ? "red" : "blue"}} href={`/admin/students/${status._id}/`}>
                            {status.name} ({status.count})
                        </a>
                    </div>
                ))}
            </div>


            <Table template={{
                collection: "students",
                title: "Студенты",
                items: [
                    {title: "Статус", key: "status", editor: "hidden", db: id},
                    {title: "Статус", key: "status", editor: "db", db: {collection: "statuses", title: "name"}},
                    {title: "Семья", key: "family", editor: "db", db: {collection: "families", title: "name"}},
                    {title: "ФИО студента", key: "name", editor: "textarea"},
                    {title: "День&nbsp;рождения", key: "birthday", editor: "date", small: true},
                    {
                        title: "Пол",
                        key: "sex",
                        editor: "select",
                        small: true,
                        db: [{title: "-----", value: ""}, {title: "М", value: "male"}, {title: "Ж", value: "female"}]
                    },
                    {title: "Адрес", key: "address", editor: "textarea"},
                    {title: "Телефон", key: "phone", editor: "input"},
                    {title: "Комментарии", key: "comment", editor: "textarea"},
                    {title: "Фото", key: "photo", editor: "image"},

                ]
            }}/>
        </>
    )

}