import React from "react";
import Table from "../../modules/table/table";


export default function Incomes() {
    return (
        <Table template={{
            collection: "incomes",
            title: "Оплата за обучение",
            items: [
                {title: "Дата", key: "date", editor: "date"},
                {title: "За студента", key: "student", editor: "db", db: {collection: "students", title: "name"}},
                {title: "За группу", key: "group", editor: "db", db: {collection: "groups", title: "name"}},
                {title: "За кол-во месяцев", key: "month", editor: "input"},
                {title: "Сумма", key: "value", editor: "input"},
                {title: "Комментарии", key: "comment", editor: "textarea"},
                {title: "Чек", key: "invoice", editor: "image"},
            ]
        }}/>
    )

}