import React from "react";

export default function Brandbook() {
    return (
        <>
            <table border={1}>
                <tbody>
                <tr>
                    <td>Название</td>
                    <td>Future Simple</td>
                </tr>
                <tr>
                    <td>Логотип</td>
                    <td>
                        <a target="_blank" href="/brandbook/logo.png"><img alt="" width={50} src="/brandbook/logo.png"/>PNG</a>
                        <a target="_blank" href="/brandbook/logo.svg"><img alt="" width={50} src="/brandbook/logo.svg"/>SVG</a>
                    </td>
                </tr>
                <tr>
                    <td>QR-код</td>
                    <td>
                        <a target="_blank" href="/brandbook/qr-code.gif?1">
                            <img width={200} alt="" src="/brandbook/qr-code.gif?1"/>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>Цвета</td>
                    <td>
                        <ul>
                            <li style={{background: "#f50057", color: "#ffffff", padding: 8}}>#f50057</li>
                            <li style={{background: "#3f51b5", color: "#ffffff", padding: 8}}>#3f51b5</li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td>Телефон</td>
                    <td>
                        <a rel="nofollow noreferrer noopener" target="_blank" href="https://msk.tele2.ru/lk">+7 495 142-91-57</a>
                    </td>
                </tr>
                <tr>
                    <td>Домен</td>
                    <td>
                        <a rel="nofollow noreferrer noopener" target="_blank" href="https://www.nic.ru/auth/login/">futuresimple.club</a>
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>
                        <a rel="nofollow noreferrer noopener" target="_blank" href="https://mail.futuresimple.club/">info@futuresimple.club</a>
                    </td>
                </tr>
                <tr>
                    <td>Видеоприглашение</td>
                    <td>
                        <iframe title="invite" width="100%" src="https://www.youtube.com/embed/Vx9hI4byC9E" frameBorder="0"/>
                    </td>
                </tr>
                <tr>
                    <td>Принципы</td>
                    <td>
                        <ul>
                            <li>Программы курсов подготовлены Cambridge и Macmillan</li>
                            <li>В группах по 6 учеников</li>
                            <li>Преподаватели с высшим профильным педагогическим образованием</li>
                            <li>Учебники и рабочие материалы входят в стоимость</li>
                            <li>Пропущенные занятия не сгорают</li>
                            <li>При окончании выдается сертификат</li>
                            <li>При оплате за 9 месяцев — скидка 10%</li>
                            <li>Контроль успеваемости</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Шрифт</td>
                    <td>
                        <a target="_blank" href="/brandbook/roboto.woff2">Roboto</a>
                    </td>
                </tr>
                <tr>
                    <td>Футболки</td><td>С логотипом</td>
                </tr>
                <tr>
                    <td>Ручки</td><td>С логотипом</td>
                </tr>
                <tr>
                    <td>Значки</td><td>С логотипом</td>
                </tr>
                <tr>
                    <td>Визитки</td><td>Контакты, qr-code</td>
                </tr>
                <tr>
                    <td>Приложение</td><td>дз</td>
                </tr>
                <tr>
                    <td>Штамп</td>
                    <td>
                        <ul>
                            <li>futuresimple.club звездочка-оценка</li>
                            <li>ИП</li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
}