import React from "react";
import Table from "../../modules/table/table";


export default function Payments() {
    return (
        <Table template={{
            collection: "payments",
            title: "Выплаты",
            items: [
                {title: "Дата", key: "date", editor: "input"},
                {title: "Учитель", key: "teacher", editor: "db", db: {collection: "teachers", title: "name"}},
                {title: "Количество уроков", key: "quantity", editor: "input"},
                {title: "Сумма", key: "value", editor: "input"},
                {title: "Чек", key: "invoice", editor: "image"},
                {title: "Акт", key: "act", editor: "image"},
            ]
        }}/>
    )

}