import React from 'react';
import Typography from "@mui/material/Typography";
import Cards from "../cards/cards";

export default function Courses({courses}) {
    const y = new Date().getFullYear();
    return (
        <div className="section">
            <Typography gutterBottom variant="h2">Курсы {y}-{y+1}</Typography>
            <Cards cards={courses}/>
        </div>
    )
}
