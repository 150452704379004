import React, {useEffect} from "react";
import {onLoadedAll} from "../../firebase/data";
import Less from "../../modules/less/less";
import Table from "../../modules/table/table";

export default function Finance() {
    const [data, setData] = React.useState(false);

    useEffect(() => {
        onLoadedAll(["discounts", "students", "statuses", "studentGroups", "groups", "courses", "teachers", "costs"])
            .onUpdate(({discounts, studentGroups, groups, courses, students, statuses, teachers, costs}) => {
                //courses = courses.filter(course => course.public);
                //courses = courses
                  //  .filter(course =>!course.id.includes("test"))
                  //  .filter(course =>!course.id.includes("online"));

                const actualStudents = students.filter(student => (statuses.filter(status => status.isActual)).map(status => status._id).includes(student.status));
                const groupsOfActualStudents = studentGroups.filter(sg => actualStudents.map(s=>s._id).includes(sg.student)).map(sg => sg.group);
                const existingGroups = groups.filter(g => groupsOfActualStudents.includes(g._id));
                courses = courses.filter(course => existingGroups.map(g => g.course).includes(course._id));

                const coursesIds = courses.map(g => g._id);
                const g = groups.filter(g => coursesIds.includes(g.course));
                const sg = studentGroups.filter(sg => g.map(s => s._id).includes(sg.group)).map(s => s.student);
                const problemStudents = actualStudents.filter(s => !students.filter(s => sg.includes(s._id)).map(s => s._id).includes(s._id)).map(s => s.name);

                console.log({problemStudents});

                groups = groups.filter(group => courses.map(course => course._id).includes(group.course));
                studentGroups = studentGroups.filter(sg => groups.map(group => group._id).includes(sg.group));
                statuses = statuses.filter(status => status.isActual);


                const calculate = (statuses) => {
                    const item = {dohod: 0, teachersMonthSalary: 0, monthCount: 0, hourSalary: 0};

                    students = students.filter(student => studentGroups.map(sg => sg.student).includes(student._id));
                    students = students.filter(student => statuses.map(status => status._id).includes(student.status));

                    students.forEach((student) => {
                        studentGroups.filter(sg => sg.student === student._id).forEach(sg => {
                            const {group, price} = sg;
                            if (price) {
                                item.dohod += +price;
                            } else {
                                const {course} = groups.filter(g => g._id === group)[0];
                                const {price} = courses.filter(c => c._id === course)[0];
                                const date = new Date();
                                const discount = discounts.filter(discount => new Date(discount.start) <= date && new Date(discount.end) >= date)[0] || {value: 0};
                                item.dohod += +price * (1 - discount.value / 100)
                            }
                        });
                    });

                    studentGroups = studentGroups.filter(sg => students.map(student => student._id).includes(sg.student));
                    groups = groups.filter(group => studentGroups.map(sg => sg.group).includes(group._id));

                    console.log("----");

                    groups.forEach(group => {
                        const {weekCount} = courses.filter(course => course._id === group.course)[0];

                        const {hourSalary} = teachers.filter(teacher => teacher._id === group.teacher)[0];
                        item.teachersMonthSalary += hourSalary * weekCount * 4;
                        item.monthCount += weekCount * 4;
                        item.hourSalary += +hourSalary;
                    })

                    item.costs = costs;
                    item.costsTotal = costs.map(cost => +cost.value).reduce((s, c) => s + c, 0);
                    item.hourSalary = item.teachersMonthSalary / item.monthCount;
                    item.monthCount = item.monthCount / groups.length;
                    item.groupCount = groups.length;
                    item.studentCount = students.length;
                    item.rashod = item.costsTotal + item.teachersMonthSalary;
                    item.itogo = item.dohod - item.rashod;

                    return item
                }

                setData([
                    calculate(statuses),
                    calculate(statuses.filter(status => status.hasContract)),
                ])
            })

    }, [])

    if (!data) return false;


    const [
        {
            groupCount: groupCount1,
            studentCount: studentCount1,
            teachersMonthSalary: teachersMonthSalary1,
            monthCount: monthCount1,
            hourSalary: hourSalary1,
            costs: costs1,
            rashod: rashod1,
            dohod: dohod1,
            itogo: itogo1
        },
        {
            groupCount: groupCount2,
            studentCount: studentCount2,
            teachersMonthSalary: teachersMonthSalary2,
            monthCount: monthCount2,
            hourSalary: hourSalary2,
            costs: costs2,
            rashod: rashod2,
            dohod: dohod2,
            itogo: itogo2
        },
    ] = data;

    return (
        <>
            <Table template={{
                collection: "costs",
                title: "Расходы",
                items: [
                    {title: "Название", key: "name", editor: "input"},
                    {title: "Сумма в месяц", key: "value", editor: "input"},
                ]
            }}/>


            <Less file={require("./finance.less")}>
                <table className={"report"}>
                    <thead>
                    <tr>
                        <th/>
                        <th>Актуальные</th>
                        <th>С договором</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>Кол-во учеников</th>
                        <td>{studentCount1.toLocaleString()}</td>
                        <td>{studentCount2.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th>Групп</th>
                        <td>{groupCount1.toLocaleString()}</td>
                        <td>{groupCount2.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <th>В среднем в группе учеников</th>
                        <td>{(Math.round(studentCount1 / groupCount1 *10)/10).toLocaleString()}</td>
                        <td>{(Math.round(studentCount2 / groupCount2 *10)/10).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th>В среднем в группе уроков в месяц</th>
                        <td>{(Math.round(monthCount1*10)/10).toLocaleString()}</td>
                        <td>{(Math.round(monthCount2*10)/10).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th>Средняя учительская ставка в час</th>
                        <td>{Math.round(hourSalary1).toLocaleString()} ₽</td>
                        <td>{Math.round(hourSalary2).toLocaleString()} ₽</td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            <hr/>
                        </td>
                    </tr>




                    {costs1.map((cost, i) => (
                        <tr key={i}>
                            <th>{cost.name}</th>
                            <td>{(+cost.value).toLocaleString()} ₽</td>
                            <td>{(+costs2[i].value).toLocaleString()} ₽</td>
                        </tr>
                    ))}
                    <tr>
                        <th>З/П учителям в месяц</th>
                        <td>{teachersMonthSalary1.toLocaleString()} ₽</td>
                        <td>{teachersMonthSalary2.toLocaleString()} ₽</td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            <hr/>
                        </td>
                    </tr>

                    <tr>
                        <th>Расходы</th>
                        <td>{rashod1.toLocaleString()} ₽</td>
                        <td>{rashod2.toLocaleString()} ₽</td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <th>Доход</th>
                        <td>{dohod1.toLocaleString()} ₽</td>
                        <td>{dohod2.toLocaleString()} ₽</td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <th>Итого</th>
                        <td>{itogo1.toLocaleString()} ₽</td>
                        <td>{itogo2.toLocaleString()} ₽</td>
                    </tr>
                    </tbody>
                </table>
            </Less>
        </>
    )
}
