import React, {useEffect} from "react";
import 'firebase/compat/firestore';
import Blank from "../blank/blank";
import {onLoadedAll} from "../../firebase/data";
import {CircularProgress} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import {Email} from "@mui/icons-material";
import QrCode from "../../qr-code.svg";
import Less from "../../modules/less/less";


const padding = 8;

const getDiscount = (discounts) => {
    for (const i in discounts) {
        const {start, end, value} = discounts[i];
        const d = new Date();
        const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        const dStart = new Date(start);
        const dEnd = new Date(end);
        if (today <= dEnd && today >= dStart) return {
            coef: (1 - value / 100),
            end: dEnd.toLocaleDateString()
        }
    }
}

const TBL = ({list, template: {items}}) => {
    return (
        <table border={1} style={{borderCollapse: "collapse", fontSize: 15, margin: 30, marginTop: 20}}>
            <thead>
            <tr>{items.map(({title}) => <th style={{background: "#a9bce5", padding}} key={title}>{title}</th>)}</tr>
            </thead>
            <tbody>
            {list.map((data) => (
                <tr key={data._id}>
                    {items.map(({key, key2, postfix, textAlign}, i) =>
                        <td style={{width: i === 0 ? "60%" : "17%", textAlign, padding}} key={key}>{data[key]} {postfix}
                            <div style={{fontSize: 11}}>{data[key2]}</div>
                        </td>
                    )}
                </tr>
            ))}
            </tbody>
        </table>
    )
};
const TBL2 = ({data: {courses, discounts, parties, partiesCourses, settings}}) => {
    const discount = getDiscount(discounts);
    parties.forEach(party => party.courses = partiesCourses.filter(pc => pc.party === party._id).map(pc => pc.course = courses.filter(course => course._id === pc.course)[0]));
    const s = {fontSize: 30, display: "flex", marginBottom: 30};
    const d = {fontSize: 30, color: "grey", marginRight: 10};

    return (
        <>
            {parties.map(party => (
                <Blank key={party._id}>
                    <div className="container noborder">
                        <h3>{party.name} </h3>
                        <h1>от {party.start}{party.end ? ` до ${party.end}` : ""} лет</h1>
                        <hr/>
                        <h2>Запишитесь на бесплатный урок:</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <img alt={""} width={200} src={QrCode}/>
                                </td>
                                <td style={{paddingTop: 20}}>
                                    <div style={s}><PhoneIcon style={d}/>{settings[0].phone}</div>
                                    <div style={s}><Email style={d}/>{settings[0].email}</div>
                                    <div style={s}><LanguageIcon style={d}/>{settings[0].site}</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="page-break"/>
                    </div>
                    <div className="container">

                        <h1>В нашей школе</h1>
                        <hr/>
                        <ul className={"lines"}>
                            <li>{settings[0].line1}</li>
                            <li>{settings[0].line2}</li>
                            <li>{settings[0].line3}</li>
                            <li>{settings[0].line4}</li>
                            <li>{settings[0].line5}</li>
                            <li>{settings[0].line6}</li>
                        </ul>
                        <div className="page-break"/>
                    </div>

                    {party.courses.map((course) => (
                        <div className="container" key={course._id}>

                            <h1>{course.headline} </h1>
                            <h2> {course.line1}</h2>
                            <hr/>
                            <div className={"tagline"}>
                                {(pp => pp.map((p, i) => (
                                    <p key={i}>{p}{i === pp.length - 1 ? "" : "."}</p>
                                )))(course.tagline.trim().split(". "))
                                }
                            </div>
                            <table style={{marginTop: 28}}>
                                <tbody>

                                <tr>
                                    <th>Занятия</th>
                                    <td>{course.line3}</td>
                                </tr>
                                <tr>
                                    <th>Стоимость в месяц</th>
                                    <td>
                                        {discount
                                            ? (
                                                <span>
                                                <span className="diagonal-strike">{course.price}₽</span>
                                                <i> <b>{course.price * discount.coef}₽</b> &nbsp; (скидка до {discount.end})</i>
                                            </span>
                                            )
                                            : <b>{course.price} ₽</b>
                                        }
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                            <div className="page-break"/>
                        </div>
                    ))}
                </Blank>
            ))}
        </>
    )
};


function Table({template}) {
    const [data, setData] = React.useState();

    useEffect(() => onLoadedAll(["courses", "discounts", "parties", "partiesCourses", "settings"]).onUpdate(setData), [])

    if (!data) return <CircularProgress/>;

    return (
        <TBL2 data={data} template={template}/>
    )

}

export default function BlankCourses() {
    return (
        <Less file={require("./blank-courses.less")}>
            <Table template={{
                collection: "courses",
                title: "Курсы",
                items: [
                    {title: "Курс", key: "headline", key2: "tagline"},
                    {title: "Возраст", key: "line1"},
                    {title: "Занятия", key: "line3"},
                    {title: "Стоимость в месяц", key: "price", postfix: "₽", textAlign: "center"}
                ]
            }}/>
        </Less>
    )

}