import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, {useEffect, useRef, useState} from "react";
import {downloadFile} from "./storage";
import {Link} from "@mui/material";

const updater = {};
const data = {};
const functions = [];
const connectUpdater = (collections, onUpdate) => {
    onUpdate((func) => {
        functions.push(func);
        for (const i in collections) {
            const collection = collections[i];

            if (updater[collection]) return data[collection] && func();

            updater[collection] = true;
            firebase.firestore().collection(collection).onSnapshot(snapshot => {
                const values = snapshot.docs.map(d => d.data());
                values.sort((a, b) => (a._date || 100000) - (b._date || 100000));
                values.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));

                data[collection] = values;
                functions.map(func => func());
            }, error => console.error(collection, error));
        }
    })
}

export const Avatar = ({url, size, stretchSize}) => {
    const [stretch, setStretch] = useState(false);

    return (
        <div className={`${stretch && "stretch"}`}>
            <div
                className="logo"
                style={{
                    width: stretch ? stretchSize : size,
                    height: stretch ? stretchSize : size,
                    cursor: url && "pointer",
                    backgroundImage: `url(${url})`
                }}
                onClick={() => url && setStretch(!stretch)}
            />
        </div>
    )
}

export const Dates = ({history, setEvents, setDate}) => {
    const [currentDate, setCurrentDate] = useState();
    const containerRef = useRef(null);
    const elementToScrollToRef = useRef(null);


    const today = (d => new Date(d.getFullYear(), d.getMonth(), d.getDate()))(new Date());

    let nexted = false;
    history.forEach(h => {
        h[2] = new Date(h[0].split(".").reverse().join("-"));
        h[3] = today <= h[2];
        if (!nexted && h[3]) {
            h[4] = true;
            nexted = true;
        }
    })

    useEffect(() => {
        if (history.length < 1) return;
        try {
            const container = containerRef.current;
            const elementToScrollTo = elementToScrollToRef.current;
            container.scrollLeft = elementToScrollTo.offsetLeft - window.document.body.clientWidth / 5;
            const rusDate = history.filter(h => h[4])[0][0];
            setEvents(history.filter(([date]) => date === rusDate)[0][1]);
            setDate(rusDate);
            setCurrentDate(rusDate)
        } catch (e) {

        }




    }, []);

    const weeks = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

    return (
        <div ref={containerRef} style={{display: "flex", overflowX: "scroll"}}>
            {history.map(([rusDate, events, date, future, next], i) => (
                <Link
                    key={i}
                    ref={next ? elementToScrollToRef : null}
                    underline={"hover"}
                    style={{
                        padding: 30,
                        cursor: "pointer",
                        background: future ? "#00ff0011" : "#00000011",
                        color: future ? "#000000" : "#00000044",
                        fontWeight: currentDate === rusDate && "bold"
                    }}
                    onClick={() => {
                        setEvents(history.filter(([date]) => date === rusDate)[0][1]);
                        setDate(rusDate);
                        setCurrentDate(rusDate)
                    }}
                >
                    <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <div>{rusDate}</div>
                        <small>{weeks[date.getDay()]}</small>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export const roubles = (val) => {
    const words = [
        [
            '', 'один', 'два', 'три', 'четыре', 'пять', 'шесть',
            'семь', 'восемь', 'девять', 'десять', 'одиннадцать',
            'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать',
            'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'
        ],
        [
            '', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят',
            'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'
        ],
        [
            '', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот',
            'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'
        ]
    ];

    const toFloat = function (number) {
        return parseFloat(number);
    };

    const plural = function (count, options) {
        if (options.length !== 3) {
            return false;
        }

        count = Math.abs(count) % 100;
        const rest = count % 10;

        if (count > 10 && count < 20) {
            return options[2];
        }

        if (rest > 1 && rest < 5) {
            return options[1];
        }

        if (rest === 1) {
            return options[0];
        }

        return options[2];
    };

    const parseNumber = function (number, count) {
        let first;
        let second;
        let numeral = '';

        if (number.length === 3) {
            first = number.substr(0, 1);
            number = number.substr(1, 3);
            numeral = '' + words[2][first] + ' ';
        }

        if (number < 20) {
            numeral = numeral + words[0][toFloat(number)] + ' ';
        } else {
            first = number.substr(0, 1);
            second = number.substr(1, 2);
            numeral = numeral + words[1][first] + ' ' + words[0][second] + ' ';
        }

        if (count === 0) {
            numeral = numeral + plural(number, ['рубль', 'рубля', 'рублей']);
        } else if (count === 1) {
            if (numeral !== '  ') {
                numeral = numeral + plural(number, ['тысяча ', 'тысячи ', 'тысяч ']);
                numeral = numeral.replace('один ', 'одна ').replace('два ', 'две ');
            }
        } else if (count === 2) {
            if (numeral !== '  ') {
                numeral = numeral + plural(number, ['миллион ', 'миллиона ', 'миллионов ']);
            }
        } else if (count === 3) {
            numeral = numeral + plural(number, ['миллиард ', 'миллиарда ', 'миллиардов ']);
        }

        return numeral;
    };

    const rubles = function (number) {
        if (!number) {
            return false;
        }

        const type = typeof number;
        if (type !== 'number' && type !== 'string') {
            return false;
        }

        if (type === 'string') {
            number = toFloat(number.replace(',', '.'));

            if (isNaN(number)) {
                return false;
            }
        }

        if (number <= 0) {
            return false;
        }

        let splt;

        number = number.toFixed(2);
        if (number.indexOf('.') !== -1) {
            splt = number.split('.');
            number = splt[0];
        }

        let numeral = '';
        let length = number.length - 1;
        let parts = '';
        let count = 0;
        let digit;

        while (length >= 0) {
            digit = number.substr(length, 1);
            parts = digit + parts;

            if ((parts.length === 3 || length === 0) && !isNaN(toFloat(parts))) {
                numeral = parseNumber(parts, count) + numeral;
                parts = '';
                count++;
            }

            length--;
        }

        numeral = numeral.replace(/\s+/g, ' ');


        return numeral;
    };


    return rubles(val);

};


export const Topic = ({data: {text, files} = {text: "", files: []}, nofiles}) => {

    return (
        <div>
            <div>
                <pre style={{whiteSpace: "pre-wrap"}}>
                    {text}
                </pre>
            </div>
            <div style={{margin: "8px 0"}}>
                {!nofiles && files.map(({name, src}, i) => (
                    <div key={i}>
                        <button onClick={() => downloadFile(src)}>{name}</button>
                    </div>
                ))}
            </div>
        </div>
    )
}


const n = (s) => s < 10 ? `0${s}` : s;
const rus = (d) => `${n(d.getDate())}.${n(d.getMonth() + 1)}.${d.getFullYear()}`;
export const onLoadedAll = (collections) => {
    const functions = [];
    const isFinish = () => {
        for (const i in collections) {
            if (!data[collections[i]]) return false;
        }
        return true
    }
    const onData = () => {
        if (isFinish()) {
            functions.map(func => func(data));
        }
    }
    const onUpdate = (func) => {
        functions.push(func);
        connectUpdater(collections, onUpdate => onUpdate(onData));
    };
    return {onUpdate}
}
export const studentBalance = (studentId, onData) => {
    onLoadedAll(["incomes", "ratings", "studentGroups", "groups"])
        .onUpdate(({incomes, ratings, studentGroups, groups}) => {
            const myData = {};
            myData.groupsIDs = studentGroups.filter(sg => sg.student === studentId).map(sg => sg.group);
            myData.ratings = ratings.filter(r => r.student === studentId);
            myData.incomes = incomes.filter(r => r.student === studentId);
            myData.groups = groups.filter(g => myData.groupsIDs.includes(g._id));
            myData.studying = {};
            myData.paid = {};
            myData.total = {};

            myData.groups.forEach(group => {
                myData.studying[group._id] = myData.ratings.filter(i => i.present && i.group === group._id).map(r => (r.date).split(".").slice(1).join("")).filter((c, i, a) => a.indexOf(c) === i).length;
                myData.paid[group._id] = myData.incomes.filter(i => i.group === group._id).reduce((s, c) => s + (+c.month), 0);
                myData.total[group._id] = myData.paid[group._id] - myData.studying[group._id];
            });

            const {studying, paid, total} = myData;

            onData({studying, paid, total})
        })
};


const createHistory = (defSchedules, holidays, reschedulings, groups, cabinets) => {
    const daysIteration = (cb) => {
        const start = groups.map(g => new Date(g.start)).sort((a, b) => a - b)[0];
        const end = groups.map(g => new Date(g.end)).sort((a, b) => b - a)[0];

        const schedules = {};
        defSchedules.forEach(s => {
            for (let day = 0; day < 7; day++) {
                const group = s[day];
                const time = s.time;
                const cabinet = s.cabinet;
                if (group) {
                    schedules[day] = schedules[day] || {};
                    schedules[day][time] = schedules[day][time] || {};
                    schedules[day][time][cabinet] = group;
                }
            }
        });

        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
            const day = date.getDay();
            const rusDate = rus(date);

            reschedulings.filter(r => r.type === "assign" && r.date === rusDate).forEach(cb);

            if (!holidays.includes(rusDate)) {
                for (const time in schedules[day]) {
                    for (const cabinet in schedules[day][time]) {
                        const group = schedules[day][time][cabinet];
                        const groupData = groups.filter(g => g._id === group)[0];

                        if (groupData && (new Date(groupData.start) <= date) && (new Date(groupData.end) >= date)) {
                            if (!reschedulings.filter(r => r.group === group && r.type === "cancel" && r.date === rusDate && r.time === time).length) {
                                cb({group, cabinet, date: rusDate, time})
                            }
                        }
                    }
                }
            }
        }
    }

    const events = {};
    const pIndex = {};
    let previousHomeWorks = {};
    daysIteration(({group, cabinet, date, time}) => {
        group = groups.filter(g => g._id === group)[0];

        if (!group) return;

        cabinet = cabinets.filter(c => c._id === cabinet)[0];

        pIndex[group._id] = pIndex[group._id] || 0;

        const {topic = {text: "", files: []}, homework = {text: "", files: []}} = group.programData[pIndex[group._id]] || {};

        const previousHomeWork = previousHomeWorks[group._id] || {text: "", files: []};


        events[date] = events[date] || [];
        events[date].push({time, group, cabinet, topic, homework, previousHomeWork});
        previousHomeWorks[group._id] = homework;

        pIndex[group._id] = pIndex[group._id] + 1;
    });

    return Object.entries(events).map(([d, e]) => ([d, e.sort((a, b) => a.time.replace(":", "") - b.time.replace(":", ""))]));
}


export const getData = (email, onData, role) => {
    onLoadedAll(["statuses", "families", "incomes", "ratings", "teachers", "cabinets", "holidays", "students", "studentGroups", "schools", "courses", "groups", "reschedulings", "schedules", "programs"])
        .onUpdate(({families, incomes, ratings, teachers, cabinets, holidays, students, studentGroups, schools, courses, groups, reschedulings, schedules, programs, statuses}) => {
            holidays = holidays.map(holiday => rus(new Date(holiday.date)));
            students.forEach(s => s.groupIds = studentGroups.filter(sg => sg.student === s._id).map(sg => sg.group));
            students.forEach(s => s.prices = studentGroups.filter(sg => sg.student === s._id).map(sg => ({group: sg.group, price: sg.price})));
            groups.forEach((group) => {
                group.courseData = courses.filter(course => course._id === group.course)[0] || {};
                group.schoolData = schools.filter(school => school._id === group.school)[0];
                group.teacherData = teachers.filter(teacher => teacher._id === group.teacher)[0];
                group.programData = programs.filter(p => p.course === group.course);
                group.studentsData = students.filter(student => student.groupIds.includes(group._id)).filter(sd => statuses.filter(status => status._id === sd.status && status.isActual).length)
            })
            if (role === "teacher") {
                const teacher = teachers.filter(t => t.login === email.split("@")[0])[0];
                teacher.groups = groups.filter(g => g.teacher === teacher._id);
                teacher.history = createHistory(schedules, holidays, reschedulings, teacher.groups, cabinets);

                schedules.forEach(schedule => {
                    for (const i in schedule) {
                        if (!isNaN(+i)) {
                            const g = teacher.groups.filter(group => group._id === schedule[i])[0];
                            const c = g && g.studentsData ? g.studentsData.length : 0;
                            const n = g && g.name ? g.name : "";
                            if (!!c && !!n) {
                                schedule.timeExists = true;
                            }

                            schedule[`g${i}`] = !!c && !!n && (
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{
                                        width: 12,
                                        height: 12,
                                        textAlign: "center",
                                        background: "rgba(44,234,44,0.3)",
                                        borderRadius: "50%",
                                        fontSize: 10,
                                        marginRight: 4
                                    }}>{c}</div>
                                    <div>{n}</div>
                                </div>
                            );
                        }
                    }
                });


                onData({ready: true, teacher, schedules})
            }
            if (role === "family") {
                const family = families.filter(t => t.login === email.split("@")[0])[0];
                family.students = students.filter(s => s.family === family._id);
                family.students.forEach(student => {
                    student.ratings = ratings.filter(r => r.student === student._id);
                    student.incomes = incomes.filter(r => r.student === student._id);
                    student.groups = groups.filter(g => student.groupIds.includes(g._id));
                    student.balance = {studying: {}, total: {}, paid: {}};
                    studentBalance(student._id, (balance) => student.balance = balance);
                    student.history = createHistory(schedules, holidays, reschedulings, student.groups, cabinets);
                });

                onData({ready: true, family})
            }
        });
};


