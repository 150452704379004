import React from "react";


export default function Footer() {
    return (
        <div style={{marginTop: 100, padding: 20, borderTop: "solid 1px #00000044"}}>
            <div style={{display: "flex", justifyContent: "center"}}>
                Copyright&nbsp;{new Date().getFullYear()}&nbsp;© Школа&nbsp;английского&nbsp;языка
                Future&nbsp;Simple.
                Все&nbsp;права&nbsp;защищены.
            </div>
        </div>
    )

}