import React from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import PlaceIcon from '@mui/icons-material/Place';
import TodayIcon from '@mui/icons-material/Today';
import QrCode from "../../qr-code.svg";


function Title() {
    const w = 1000;
    const h = 1000;

    const y1 = 500;
    const y2 = 800;

    const font1=500;
    const font2=300;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w * 4} ${h}`} >
            <text y={y1} textLength="95%" fontSize={font1}>FUTURE SIMPLE</text>
            <text x={25} y={y2}  textLength="calc(95% - 50px)" fontSize={font2}>школа английского языка</text>
        </svg>
    )
}

export default function Logotype2() {
    const s = {fontSize: 30, display: "flex", marginBottom: 20};
    const d = {fontSize: 30, color: "grey", marginRight: 4};
    return (
        <>
            <style>
                {
                    `
                        @page {
                            size: A4 landscape;
                        }
                        
                        .container2 {
                            margin: 10px
                        }
                    `
                }

            </style>
            <div className={"container2"}>
                <Title/>
                <table style={{marginTop: 100}}>
                    <tbody>
                    <tr>
                        <td><img alt={""} width={300} src={QrCode}/></td>
                        <td style={{  paddingLeft: 20, paddingTop: 20}}>
                            <div style={s}><PhoneIcon style={d}/>+7 495 142-91-57</div>
                            <div style={s}><LanguageIcon style={d}/>FutureSimple.club</div>
                            <div style={s}><PlaceIcon style={d}/>Марьинский бульвар, 10</div>
                            <div style={s}><TodayIcon style={d}/>Понедельник — пятница: с 12:00 до 20:00.<br/>Суббота и воскресенье — выходные.</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
