import "./student.css";
import {Rating} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, {useRef, useState} from "react";
import {Avatar, Dates, Topic} from "../firebase/data";
import {BasicTabs} from "./tabs";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {LifePayWidget} from "../modules/lifepos";
import {TelegramBotSendMessage} from "../telegrambot/telegrambot";

const PromoCode = ({login}) => {
    const splitStringByThreeCharacters = (inputString) => {
        const regex = /(.{1,3})/g;
        const resultArray = inputString.match(regex);
        return resultArray.join('-');
    };
    const promoCode = splitStringByThreeCharacters(login) + "00";
    const CopyMessage = ({title, text}) => {
        const textRef = useRef(null);
        const [open, setOpen] = useState(false);
        const copyLink = () => {
            const input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        }
        const handleSelectText = () => {
            if (textRef.current) {
                const textNode = textRef.current;
                const range = document.createRange();
                range.selectNodeContents(textNode);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        };
        const handleCopyClick = () => {
            copyLink();
            handleSelectText();
            setOpen(true);
            setTimeout(() => {
                setOpen(false)
            }, 2000);
        };
        return (
            <>
                <span title={title} onClick={handleCopyClick} ref={textRef}
                      style={{border: "dotted 1px #000000", background: "rgba(55,255,0,0.62)", whiteSpace: "nowrap"}}>
                    {text}
                </span>
                <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
                    <MuiAlert elevation={6} variant="filled" onClose={() => setOpen(false)} severity="success">
                        Промокод скопирован!
                    </MuiAlert>
                </Snackbar>
            </>
        );
    }
    return (
        <div style={{display: "flex"}}>
            <b>Промокод для друга:&nbsp;</b>
            <CopyMessage title="Нажмите, чтобы скопировать" text={promoCode}/>
            &nbsp; (друг получит скидку -2000₽, а Вы получите -1000₽ на оплату следующего месяца)
            &nbsp;
            <a target={"_blank"} href={"/recommend/"}>полные условия</a>
        </div>
    )
}


export const StudentView = ({data: student}) => {
    const {history, groups} = student;
    const [events, setEvents] = useState([]);
    const [date, setDate] = useState("");

    return (
        <div>
            <table style={{margin: 10}}>
                <tbody>
                {groups.map((group, i) => (
                    <tr key={i}>
                        <td style={{margin: 10, verticalAlign: "middle"}}>
                            <div style={{fontWeight: "bold"}}>{group.name}</div>
                        </td>
                        <td style={{
                            margin: 10,
                            verticalAlign: "middle",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Avatar size={40} stretchSize={100} url={group.teacherData.photo}/>
                                <div style={{marginLeft: 10}}>
                                    <small>Учитель</small>
                                    <div>{group.teacherData.name}</div>
                                </div>
                            </div>
                        </td>
                        <td style={{margin: 10, verticalAlign: "middle"}}>
                            {group.telegram && <div><a target="_blank" href={group.telegram}>Telegram</a></div>}
                        </td>
                        <td style={{margin: 10, verticalAlign: "middle"}}>
                            <div>Обучается: {student.balance.studying[group._id]} мес</div>
                            <div
                                style={{background: student.balance.total[group._id] < 0 && "red"}}>Оплачено: {student.balance.paid[group._id]} мес
                            </div>
                        </td>
                        <td>
                            <LifePayWidget
                                data={{
                                    name: `Обучение ${group.courseData.headline}. ${student.name}`,
                                    cost: student.prices.filter(p => p.group === group._id)[0].price,
                                    on_success: () => {
                                        TelegramBotSendMessage(`Оплата\nОбучение ${group.courseData.headline}. ${student.name}\n${student.prices.filter(p => p.group === group._id)[0].price}`);
                                    },
                                    on_fail: () => {
                                        TelegramBotSendMessage(`Ошибка платежа\nОбучение ${group.courseData.headline}. ${student.name}\n${student.prices.filter(p => p.group === group._id)[0].price}`);
                                    },
                                }}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <hr/>

            <Dates history={history} setEvents={setEvents} setDate={setDate}/>

            <div style={{margin: 2}}>
                {events.map(({group, previousHomeWork}, j) => {
                    return previousHomeWork.text && (
                        <div key={j} style={{padding: 20, margin: 20, background: "#a9bce533"}}>
                            <h4>Домашнее задание <nobr>{group.name}</nobr></h4>
                            <div className="top"><Topic data={previousHomeWork}/></div>
                        </div>
                    )
                })}
                <table className={"history"}>
                    <thead>
                    <tr>
                        <th>Время</th>
                        <th>Группа</th>
                        <th>Кабинет</th>
                        <th>Тема урока</th>
                        <th>Оценка</th>
                    </tr>
                    </thead>
                    <tbody style={{maxHeight: 300, overflowY: "scroll"}}>
                    {events.map(({time, group, cabinet, topic, homework, present}, j) => {
                        const rating = student.ratings.filter(r => r.date === date && r.time === time)[0] || {};
                        return (
                            <React.Fragment key={j}>
                                <tr style={{background: rating.present ? "#00ff0044" : "#00000011"}}>
                                    <td>{time}</td>
                                    <td>
                                        <nobr>{group.name}</nobr>
                                    </td>
                                    <td>{cabinet.name}</td>
                                    <td className="top"><Topic data={topic} nofiles={true}/></td>
                                    <td>
                                        <Rating value={+rating.rate} max={10} readOnly/>
                                        {rating.comment && <div><b>Учитель:</b> <i>{rating.comment}</i></div>}
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const StudentsView = ({family}) => {
    const {students} = family;


    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <td>
                        <button onClick={() => firebase.auth().signOut()}>Выйти</button>
                    </td>
                    <td>
                        <PromoCode login={family.login}/>
                    </td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <BasicTabs students={students}/>
        </div>
    )
}
