import React from "react";
import Roboto from "../../Roboto-Bold.ttf";
import Lightning from "../../lightning.png";
import Clock from "../../clock.png";



export default class OpenClosed extends React.Component{
    constructor(props) {
        super(props);
        this.state = {width: 200, height: 25, text: "Школа английского языка", fontFamily: "Roboto"};
    }

    componentDidMount() {
        (async ()=>{
            const font = new FontFace(this.state.fontFamily, `url("${Roboto}")`);
            await font.load();
            document.fonts.add(font);
        })()
    }

    render() {
        return (
            <>
                <div style={{color: "#ffffff", background: "#4caf50", height: 792, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: 130, display: "flex", alignItems: "center"}}>
                        <div>Заходите</div>
                        <div>🤩</div>
                    </div>
                    <div style={{fontSize: 130}}>Мы открыты!</div>
                    <div style={{fontSize: 400, lineHeight: 1.1}}>OPEN</div>
                </div>
                <div style={{pageBreakAfter: "always"}}/>
                <div style={{ color: "#ffffff", background: "#ff0000", height: 792, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: 300}}>CLOSED</div>
                    <div style={{fontSize: 130}}>Ждём вас</div>
                    <div style={{fontSize: 130}}>c 12:00 утра</div>
                </div>
                <div style={{ color: "#ffffff", background: "#ff0000", height: 792, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: 300}}>CLOSED</div>
                    <div style={{fontSize: 100, textAlign: "center"}}>Ждём вас <br/>в понедельник <br/>c 12:00 утра</div>
                </div>
                <div style={{ color: "#ffffff", background: "#000000", height: 792, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: 180, marginTop: 50}}>STAFF ONLY</div>
                    <img alt={""} src={Lightning} height={100} style={{margin: 100}}/>
                    <div style={{fontSize: 90}}>Служебное помещение</div>
                </div>
                <div style={{ color: "#ffffff", background: "#ff0000", height: 792, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: 180, marginTop: 50}}>BREAK TIME</div>
                    <img alt={""} src={Clock} height={100} style={{margin: 100}}/>
                    <div style={{fontSize: 90}}>Технический перерыв</div>
                </div>
            </>
        )
    }





}