import React, {useEffect, useState} from "react";
import Table from "../../modules/table/table";
import firebase from "firebase/compat/app";

const List = (props) => {
    const [list, setList] = React.useState(false);
    !list && firebase.firestore().collection("cabinets").onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._date || 100000) - (b._date || 100000));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));

        firebase.firestore().collection("schools").onSnapshot((snapshot) => {
            const schools = snapshot.docs.map(d=>d.data());
            list.forEach(l=>l.school = schools.filter(s=>s._id===l.school)[0])
            setList(list);
        })
    });


    if (!list) return false;

    return (
        <ul>
            {
                list.map(({_id, name, school}) => console.log(school)  || (
                    <li key={_id}>
                        <a style={{color: _id === props._id ? "#ff0000" : "#000000"}} href={`/admin/schedules/${_id}/`}>
                            {name} [<span style={{fontSize: 10}}>{school.address}</span>]
                        </a>
                    </li>
                ))
            }
        </ul>
    )
};


const getCabinet = (id) => {
    if (!id) return false;
    return new Promise((resolve) => {
        firebase.firestore().collection("cabinets").doc(id).onSnapshot((snapshot) => {
            resolve(snapshot.data());
        })
    })
}
const getSchool = (id) => {
    if (!id) return false;
    return new Promise((resolve) => {
        firebase.firestore().collection("schools").doc(id).onSnapshot((snapshot) => {
            resolve(snapshot.data());
        })
    })
}

export default function Schedules({match: {params: {id}}}) {
    const [data, setData] = useState({school: {}, cabinet: {}});

    useEffect(() => {
        (async () => {
            const cabinet = await getCabinet(id);
            const school = await getSchool(cabinet.school);
            setData({school, cabinet});
        })()

    }, [])


    const {school} = data;


    return (
        <>
            <List _id={id}/>
            {id && (<Table template={{
                    collection: `schedules`,
                    title: "Расписание",
                    items: [
                        {title: "Кабинет", key: "cabinet", editor: "hidden", db: id},
                        {title: "Время", key: "time", editor: "time"},
                        {title: "ПН", key: "1", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "ВТ", key: "2", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "СР", key: "3", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "ЧТ", key: "4", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "ПТ", key: "5", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "СБ", key: "6", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                        {title: "ВС", key: "0", editor: "schedulesGroups", db: {cabinet: id, school: school._id}},
                    ]
                }}/>
            )}
        </>
    )

}


