import React from "react";
import Table from "../../modules/table/table";


export default function Holidays() {
    return (
        <Table template={{
            collection: "holidays",
            title: "Выходной",
            items: [
                {title: "Дата", key: "date", editor: "date"},
                {title: "Комментарии", key: "comment", editor: "input"},
            ]
        }}/>
    )

}