import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import DoneIcon from '@mui/icons-material/Done';
import Grid from "@mui/material/Grid";
import CallToAction from "../calltoaction/calltoaction";


export default function Jumbotron({headline, tagline, lines}) {
    return (
        <div className="section">
            <Typography gutterBottom variant="h1">{headline}</Typography>
            <Grid container spacing={5}>
                <Grid item xs>
                    <Typography variant="subtitle1" color="textSecondary">{tagline}</Typography>
                    <List>{lines.map(line => (
                        <ListItem disableGutters={true} key={line}>
                            <ListItemIcon style={{minWidth: 35}}><DoneIcon color="primary"/></ListItemIcon>
                            <ListItemText primary={line}/>
                        </ListItem>
                    ))}</List>
                    <div style={{marginTop: 24}}>
                        <CallToAction size="large"/>
                    </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <iframe className="youtube" title="youtube" style={{height: 0}} src="https://www.youtube.com/embed/Vx9hI4byC9E" frameBorder="0" allowFullScreen/>
                </Grid>
            </Grid>
        </div>
    )

}