import firebase from "firebase/compat/app";
import "firebase/compat/firestore";


export const saveText = (text) => {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child("json/" + (+new Date())+ ".json" ).putString(text);
    uploadTask.on(
        "state_changed",
        (snapshot) => console.log(snapshot.bytesTransferred / snapshot.totalBytes),
        (error) => console.log(error),
        () => uploadTask.snapshot.ref.getDownloadURL().then((src) => console.log(src))
    );
}


export const getNameFromSRC = (src) =>{
    return decodeURIComponent(src.split("?")[0].split("/").pop())
}
export const deleteFile = (src) => {
    firebase.storage().ref().child(getNameFromSRC(src)).delete();
}
export const uploadFile = (folder, onSuccess) => {
    const storageRef = firebase.storage().ref();
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display="none";
    document.body.appendChild(fileInput);
    fileInput.addEventListener("change", async (e) => {
        const file = e.target.files[0];
        const uploadTask = storageRef.child(folder + (+new Date())+ "." + file.name).put(file);
        document.body.removeChild(fileInput);

        uploadTask.on(
            "state_changed",
            (snapshot) => console.log(snapshot.bytesTransferred / snapshot.totalBytes),
            (error) => console.log(error),
            () => uploadTask.snapshot.ref.getDownloadURL().then((src) => onSuccess(src, file.name))
        );
    });
    fileInput.click();
}
export const downloadFile = (src) => {
    const name = getNameFromSRC(src).split("/").pop().split(".").splice(1,1).join(".");
    fetch(src)
        .then(response => response.blob())
        .then(blob => {
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = name;
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
        })
        .catch(error => console.error("Ошибка при скачивании файла: ", error));
}