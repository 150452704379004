import React from "react";
import Roboto from "../../Roboto-Bold.ttf";

export default class Title extends React.Component{
    constructor(props) {
        super(props);
        this.state = {width: 200, height: 25, text: "Школа английского языка", fontFamily: "Roboto"};
        this.getFontSize = this.getFontSize.bind(this);
        this.calculate = this.calculate.bind(this);
        this.containerComplete = this.containerComplete.bind(this);
        this.container = React.createRef();
    }

    getFontSize(font, width, height, text) {
        const parent = this.container.current.appendChild(document.createElement("div"));
        const el = parent.appendChild(document.createElement("span"));
        el.innerText = text;
        el.style.cssText = `font-family: ${font}; white-space: nowrap; font-size: 0`;
        parent.style.cssText = `width: ${width}cm; height: ${height}cm`;
        const mHeight = parent.offsetHeight;
        const mWidth = parent.offsetWidth;
        parent.style.cssText = `width: max-content; height: max-content`;

        let fontSize = 0;
        while (parent.offsetHeight <= mHeight && parent.offsetWidth <= mWidth){
            fontSize = fontSize + 0.5;
            el.style.fontSize = `${fontSize}px`;
        }
        this.container.current.removeChild(parent);

        return {height: mHeight, width: mWidth, fontSize, px: {h: mHeight/height, w: mWidth/width}}
    }

    calculate(){
        const {width, height, text, fontFamily} = this.state;
        const {fontSize} = this.getFontSize(fontFamily, width, height, text);

        this.container.current.innerHTML = "";

        for(const i in text){
            const letter1 = text[i];
            const letter2 = text[+i+1];
            const letter3 = text[+i+2];

            if(i >= text.length-1)continue;
            if(letter1 === " ")continue;

            const el = this.container.current.appendChild(document.createElement("div"));
            el.style.fontFamily = fontFamily;
            el.style.fontSize = `${fontSize}px`;
            el.style.textDecoration = "underline";

            if(letter1 && letter2) el.innerHTML =  `${letter1}${letter2}`;
            if(letter1 && letter2 ===" " && letter3) el.innerHTML =  `${letter1}${letter2}${letter3}`;
            if(letter1 === " " && letter2) el.innerHTML =  `${letter1}${letter2}${letter3}`;
        }

        for(const i in text){
            const letter1 = text[i];
            if(letter1 === " ")continue;

            const el = this.container.current.appendChild(document.createElement("div"));
            el.style.fontFamily = fontFamily;
            el.style.fontSize = `${fontSize}px`;
            el.innerHTML =  letter1;
        }

    }

    containerComplete(){
        return new Promise((resolve) => this.interval = setInterval(() => this.container.current && resolve(clearInterval(this.interval))))
    }

    componentDidMount() {
        (async ()=>{
            const font = new FontFace(this.state.fontFamily, `url("${Roboto}")`);
            await font.load();
            document.fonts.add(font);

            await this.containerComplete();
            this.calculate();
        })()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.calculate();
    }

    componentWillUnmount() {
        this.interval && clearInterval(this.interval);
    }

    render() {
        const {width, height, text} = this.state;
        return (
            <>
                <div className="noPrint">
                    <label style={{margin: 12}}>Ширина: <input style={{width: 30}} value={width} onChange={e=>this.setState({width: e.target.value})}/>см</label>
                    <label style={{margin: 12}}>Высота: <input style={{width: 30}} value={height} onChange={e=>this.setState({height: e.target.value})}/>см</label>
                    <label style={{margin: 12}}>Текст: <input style={{width: "50%"}} value={text} onChange={e=>this.setState({text: e.target.value})}/></label>
                </div>
                <div ref={this.container}/>
            </>
        )
    }

}