import React from "react";
import Table from "../../modules/table/table";


export default function Groups() {
    return (
        <Table template={{
            collection: "groups",
            title: "Группы",
            items: [
                {title: "Название", key: "name", editor: "input"},
                {title: "Начало", key: "start", editor: "date"},
                {title: "Конец", key: "end", editor: "date"},
                {title: "Курс", key: "course", editor: "db", db: {collection: "courses", title: "headline"}},
                {title: "Школа", key: "school", editor: "db", db: {collection: "schools", title: "address"}},
                {title: "Учитель", key: "teacher", editor: "db", db: {collection: "teachers", title: "name"}},
                {title: "Шаблон договора", key: "template", editor: "db", db: {collection: "templates", title: "name"}},
                {title: "Исполнитель", key: "executor", editor: "db", db: {collection: "executors", title: "abbreviated"}},
                {title: "Telegram", key: "telegram", editor: "input"},
            ]
        }}/>
    )

}