import React from "react";
import Table from "../../modules/table/table";


export default function Ratings() {
    return (
        <Table template={{
            collection: "ratings",
            title: "Посещаемость и оценки",
            items: [
                {title: "Дата", key: "date", editor: "input"},
                {title: "Время", key: "time", editor: "input"},
                {title: "Студент", key: "student", editor: "db", db: {collection: "students", title: "name"}},
                {title: "Группа", key: "group", editor: "db", db: {collection: "groups", title: "name"}},
                {title: "Кабинет", key: "cabinet", editor: "db", db: {collection: "cabinets", title: "name"}},
                {title: "Учитель", key: "teacher", editor: "db", db: {collection: "teachers", title: "name"}},
                {title: "Присутствие", key: "present", editor: "select", db: [{title: "Небыл", value: ""}, {title: "Был", value: "true"}]},
                {title: "Оценка", key: "rate", editor: "input"},
            ]
        }}/>
    )

}