import React, {useEffect} from "react";
import Less from "../../modules/less/less";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

const Table = ({list}) => (
    <Less file={require("./orders.less")}>
        <h3>Заявки</h3>
        <table>
            <thead>
            <tr>
                <th>Дата</th>
                <th>Имя</th>
                <th>Телефон</th>
                <th>Email</th>
                <th>URL</th>
                <th>Удалить</th>
            </tr>
            </thead>
            <tbody>
            {
                list.map(({id, date, name, phone, email, url}) => (
                    <tr key={date} id={id}>
                        <td>{date}</td>
                        <td>{name}</td>
                        <td>{phone}</td>
                        <td>{email}</td>
                        <td>{url}</td>
                        <td><span style={{color: "#f00", cursor: "pointer"}}
                                  onClick={() => window.confirm("Точно удалить?") && firebase.firestore().collection('orders').doc(id).delete()}>&#10006;</span>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    </Less>
);

const Progress = () => <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/></div>;

export default function Orders() {
    const [list, setList] = React.useState(false);

    useEffect(()=>{
        firebase.firestore().collection('orders').get().then(snapshot => {
            const list = [];
            snapshot.forEach((s) => (d => list.push({...d, id: s.id, date: d.date.toDate()}))(s.data()));
            list.sort((a, b) => b.date - a.date);
            list.forEach((d) => d.date = d.date.toLocaleString().replace(",",""));
            setList(list);
        }).catch((e) => {
            console.log(e)
        });

    }, [])

    if(!list)return <Progress/>



    return <Table list={list}/>
}