import React from 'react';
import {configureStore, createSlice} from '@reduxjs/toolkit';

const slices = [
    {
        name: "user",
        initialState: false,
        reducers: {
            set: (state, action) => ({email: action.payload.email, role: action.payload.role, ready: true}),
            loading: (state, action) => ({...state, ready: action.payload}),
        },
    },

].map(createSlice);

export const actions = slices.reduce((a, c) => ({...a, [c.name]: c.actions}), {});
export const RTKStore = configureStore({reducer: slices.reduce((a, c) => ({...a, [c.name]: c.reducer}), {})});






