import React from "react";
import Table from "../../modules/table/table";


export default function WelcomeDetails() {
    return (
        <Table template={{
            collection: "welcomeDetails",
            title: "Детали",
            items: [
                {title: "Дата", key: "date", editor: "fbdate"},
                {title: "Путь", key: "path", editor: "input"},
                {title: "Параметры", key: "params", editor: "input"},
                {title: "userAgent", key: "userAgent", editor: "input"},
                {title: "Реферер", key: "referrer", editor: "input"},
                {title: "Высота", key: "height", editor: "input"},
                {title: "Ширина", key: "width", editor: "input"},
            ]
        }}/>
    )

}