import React from "react";
import Table from "../../modules/table/table";


export default function Teachers() {
    return (
        <Table template={{
            collection: "teachers",
            title: "Учителя",
            items: [
                {title: "ФИО", key: "name", editor: "textarea"},
                {title: "Дата рождения", key: "birthday", editor: "input"},
                {title: "Пол", key: "sex", editor: "select", small: true, db: [{title: "-----", value: ""},{title: "М", value: "male"},{title: "Ж", value: "female"}]},
                {title: "Паспорт", key: "passport", editor: "textarea"},
                {title: "Адрес", key: "address", editor: "textarea"},
                {title: "Телефон", key: "phone", editor: "input"},
                {title: "Комментарии", key: "comment", editor: "textarea"},
                {title: "Оплата в час", key: "hourSalary", editor: "input"},
                //{title: "Email", key: "email", editor: "input"},
                {title: "Фото", key: "photo", editor: "image"},
            ]
        }}/>
    )

}