import React from "react";
import Less from "../../modules/less/less";

const subject = "английский язык";
const subjectDP = "английскому языку";

export default function Form() {
    return (
        <>
            <Less file={require("./form.less")}>
                <div className="container">
                    <div>
                        <h1>Анкета</h1>
                        <h2>Данные Заказчика:</h2>
                        <h3 className="line">Фамилия, имя, отчество</h3>
                        <h3 className="line">Серия и номер паспорта</h3>
                        <h3 className="line">Дата выдачи паспорта, выдавший орган</h3>
                        <h3 className="line">Дата и место рождения</h3>
                        <h3 className="line">Адрес регистрации по месту жительства</h3>
                        <h3 className="line">Номер телефона</h3>

                        <h2>Данные Студента, которому оказываются услуги:</h2>
                        <h3 className="line">Фамилия, имя, отчество</h3>
                        <h3 className="line">Адрес фактического проживания</h3>
                        <h3 className="line">Контактный телефон</h3>
                        <h3 style={{display: "flex"}}>
                            <div className="line" style={{width: "40%"}}>Дата рождения</div>
                            <div className="line" style={{width: "40%"}}>Школа</div>
                            <div className="line" style={{width: "40%"}}>Класс</div>
                        </h3>

                        <h2>Изучал ли Студент {subject} раньше? Если да, то где и как долго?</h2>
                        <h3>
                            <table>
                                <thead><tr><th>№</th><th>Где?</th><th>Сколько времени?</th></tr></thead>
                                <tbody>
                                <tr><td/><td/><td/></tr>
                                <tr><td/><td/><td/></tr>
                                <tr><td/><td/><td/></tr>
                                </tbody>
                            </table>
                        </h3>



                        <h2>Сведения о родителях</h2>
                        <h3>Мама</h3>
                        <h4 className="line">Фамилия, имя, отчество</h4>
                        <h4 style={{display: "flex"}}>
                            <div className="line" style={{width: "100%"}}>Телефон</div>
                        </h4>
                        <h3>Папа</h3>
                        <h4 className="line">Фамилия, имя, отчество</h4>
                        <h4 style={{display: "flex"}}>
                            <div className="line" style={{width: "100%"}}>Телефон</div>
                        </h4>

                        <h2>Как Вы узнали о нас?</h2>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>Яндекс</h3>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>VK</h3>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>Tiktok</h3>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>Youtube</h3>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>Вывеска, стенд, штендер</h3>
                        <h3 className="line" style={{width: "0%"}}><input type="checkbox"/>Листовка</h3>
                        <h3 className="line"><input type="checkbox"/>Рекомендация (от кого?)</h3>
                        <h3 className="line"><input type="checkbox"/>Другое</h3>

                        <div className="between">
                            <div className="sign col">Подпись</div>
                            <div className="sign col">Дата заполнения</div>
                        </div>
                    </div>
                </div>
            </Less>
        </>
    )

}