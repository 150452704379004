import React, {useEffect, useState} from "react";
import {onLoadedAll} from "../../firebase/data";
import firebase from "firebase/compat/app";
import {firebaseConfig} from "../../index";
import {CircularProgress} from "@mui/material";

const generatePassword = (length) => {
    const charset = "ABCDEFGHJKLMNPQRSTUVWXYZ123456789";
    let password = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}

const CreateButton = ({data: {_id, role, collection}}) => {
    const [loading, setLoading] = useState(false);

    if(loading)return <CircularProgress style={{width: 20, height: 20}}/>
    return (
        <button onClick={async () => {
            setLoading(true);
            const rolesRef = firebase.firestore().collection("roles");
            const passwordsRef = firebase.firestore().collection("passwords");
            const docRef = firebase.firestore().collection(collection).doc(_id);
            const login = (+new Date()).toString();
            const password = generatePassword(7);

            await firebase.initializeApp(firebaseConfig, login).auth().createUserWithEmailAndPassword(`${login}@nairio.com`, password);
            await rolesRef.add({login, role});
            await passwordsRef.add({login, password});
            await docRef.update({login});
            setLoading(false);
        }}>Создать</button>
    )
}
const DeleteButton = ({data: {_id, login, password, collection}}) => {
    const [loading, setLoading] = useState(false);

    if(loading)return <CircularProgress style={{width: 20, height: 20}}/>
    return (
        <button onClick={async () => {
            if(!window.confirm("Точно удалить?"))return ;
            setLoading(true);
            const rolesRef = await firebase.firestore().collection("roles").where("login", "==", login).get();
            const passwordsRef = await firebase.firestore().collection("passwords").where("login", "==", login).get();
            const docRef = firebase.firestore().collection(collection).doc(_id);

            const {user} = await firebase.initializeApp(firebaseConfig, login).auth().signInWithEmailAndPassword(`${login}@nairio.com`, password);
            await user.delete();
            await docRef.update({login: ""});
            await rolesRef.docs.map(doc => doc.ref)[0].delete();
            await passwordsRef.docs.map(doc => doc.ref)[0].delete();
            setLoading(false);
        }}>Удалить</button>
    )
}

export default function Logins() {
    const [data, setData] = useState({families: [], teachers: []});
    useEffect(() => onLoadedAll(["teachers", "families", "roles", "passwords"]).onUpdate((data) => {
        const {families, teachers, roles, passwords} = data;
        setData({families, teachers, roles, passwords})
    }), []);

    const {families, teachers, roles, passwords} = data;

    families.forEach(family => {
        family.role = (roles.filter(r => r.login === family.login)[0] || {}).role
        family.password = (passwords.filter(p => p.login === family.login)[0] || {}).password
    })

    teachers.forEach(teacher => {
        teacher.role = (roles.filter(r => r.login === teacher.login)[0] || {}).role
        teacher.password = (passwords.filter(p => p.login === teacher.login)[0] || {}).password
    })

    return (
        <>
            <h2>Логины</h2>
            <table>
                <thead>
                <tr>
                    <th>Имя</th>
                    <th>Логин</th>
                    <th>Пароль</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={4}><h3>Учителя</h3></td>
                </tr>

                {teachers.map(({_id, name, login, role, password}, i) => (
                    <tr key={i}>
                        <td>{name}</td>
                        <td>{login}</td>
                        <td>{password}</td>
                        <td>
                            {
                                role
                                    ? <DeleteButton data={{_id, login, password, collection: "teachers"}}/>
                                    : <CreateButton data={{_id, role: "teacher", collection: "teachers"}}/>
                            }
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={4}><h3>Студенты</h3></td>
                </tr>
                {families.map(({_id, name, login, role, password}, i) => (
                    <tr key={i}>
                        <td>{name}</td>
                        <td>{login}</td>
                        <td>{password}</td>
                        <td>
                            {
                                role
                                    ? <DeleteButton data={{_id, login, password, collection: "families"}}/>
                                    : <CreateButton data={{_id, role: "family", collection: "families"}}/>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
{/*            <Table template={{
                collection: "roles",
                title: "Роли",
                items: [
                    {title: "Логин", key: "login", editor: "input"},
                    {
                        title: "Роль",
                        key: "role",
                        editor: "select",
                        db: [{title: "-----", value: ""}, {title: "Учитель", value: "teacher"}, {
                            title: "Семья",
                            value: "families"
                        }, {title: "Администратор", value: "admin"}]
                    },
                ]
            }}/>
            <Table template={{
                collection: "passwords",
                title: "Пароли",
                items: [
                    {title: "Логин", key: "login", editor: "input"},
                    {title: "Пароль", key: "password", editor: "input"},
                ]
            }}/>*/}
        </>
    )

}