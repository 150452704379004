import React, {useEffect, useState} from "react";
import Table from "../../modules/table/table";
import {onLoadedAll} from "../../firebase/data";


export default function Journal() {
    const [list, setList] = useState([]);

    useEffect(() => {
        onLoadedAll(["journal", "groups", "cabinets", "teachers"]).onUpdate(({journal, groups, cabinets, teachers}) => {
            journal.forEach(journal => {
                journal.cabinetData = cabinets.filter(c => c._id === journal.cabinet)[0];
                journal.groupData = groups.filter(g => g._id === journal.group)[0];
                journal.teacherData = teachers.filter(g => g._id === journal.teacher)[0];
                journal.dateTime = new Date(journal.date.split(".").reverse().join("-") + ` ${journal.time}`);
            });
            journal.sort((a,b)=>a.dateTime-b.dateTime);
            setList(journal)
        })
    }, [])


    return (
        <>
            <table>
                <tbody>
                {list.map(({date, time, groupData, teacherData, cabinetData}, i) => (
                    <tr key={i}>
                        <td>{date} {time}</td>
                        <td>{groupData.name}</td>
                        <td>{cabinetData.name}</td>
                        <td>{teacherData.name}</td>
                    </tr>
                ))}
                </tbody>
            </table>


            <Table template={{
                collection: "journal",
                title: "Журнал",
                items: [
                    {title: "Дата", key: "date", editor: "input"},
                    {title: "Время", key: "time", editor: "input"},
                    {title: "Группа", key: "group", editor: "db", db: {collection: "groups", title: "name"}},
                    {title: "Кабинет", key: "cabinet", editor: "db", db: {collection: "cabinets", title: "name"}},
                    {title: "Учитель", key: "teacher", editor: "db", db: {collection: "teachers", title: "name"}},
                ]
            }}/>
        </>
    )

}