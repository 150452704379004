import React from "react";
import Table from "../../modules/table/table";


export default function Courses() {
    return (
        <Table template={{
            collection: "courses",
            title: "Курсы",
            items: [
                {title: "Id", key: "id", editor: "input"},
                {title: "Публиковать", key: "public", editor: "select", db: [{title: "Нет", value: ""}, {title: "Да", value: "true"}]},
                {title: "Предмет", key: "subject", editor: "db", db: {collection: "subjects", title: "name"}},
                {title: "Название", key: "headline", editor: "input"},
                {title: "Описание", key: "tagline", editor: "textarea"},
                {title: "Цена", key: "price", editor: "input"},
                {title: "В неделю", key: "weekCount", editor: "input"},
                {title: "Line 1", key: "line1", editor: "input"},
                {title: "Line 2", key: "line2", editor: "input"},
                {title: "Line 3", key: "line3", editor: "input"},
                {title: "Line 4", key: "line4", editor: "input"},
                {title: "Фото", key: "image", editor: "image"},
            ]
        }}/>
    )

}