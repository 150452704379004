import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";


export const Admin = () => {
    return (
        <div>
            <button onClick={() => firebase.auth().signOut()}>Выйти</button>
        <ul>
            <li>
                {(url => <a target="_blank" href={`//${url}`}>{url}</a>)(document.location.host.toString().split(".").slice(1).join("."))}
            </li>
            <hr/>
            <li><a target="_blank" href="/admin/brandbook/">Брендбук</a></li>
            <li><a target="_blank" href="/admin/badge/">Бейдж</a></li>
            <li><a target="_blank" href="/admin/face/">Логотип и название</a></li>
            <hr/>
            <div style={{fontSize: 30}}>Фасад</div>
            <li><a target="_blank" href="/admin/title/">Вывеска</a></li>
            <li><a target="_blank" href="/admin/logotype/">Логотип</a></li>
            <li><a target="_blank" href="/admin/logotype2/">Логотип2</a></li>
            <li><a target="_blank" href="/admin/openclosed/">Open-Closed</a></li>
            <hr/>
            <div style={{fontSize: 30}}>Листовки</div>
            <li><a target="_blank" href="/admin/flyer/">С акцией 5шт</a></li>
            <li><a target="_blank" href="/admin/flyermap/">С картой 5шт</a></li>
            <li><a target="_blank" href="/admin/flyermapa5/">С картой 1шт</a></li>
            <li><a target="_blank" href="/admin/Flyermapa7/">С QR-кодом</a></li>
            <li><a target="_blank" href="/admin/flyerchina/">Китайский</a></li>
            <hr/>
            <div style={{fontSize: 30}}>Клиенты</div>
            <li><a target="_blank" href="/admin/form/">Анкета</a></li>
            <li><a target="_blank" href="/admin/orders/">Заявки</a></li>
            <li><a target="_blank" href="/admin/blank-courses/">Бланк Курсы</a></li>
            <li><a target="_blank" href="/admin/blank/">Фирменный бланк</a></li>
            <hr/>
            <div style={{fontSize: 30}}>Студенты</div>
            <li><a target="_blank" href="/admin/families/">Семьи</a></li>
            <li><a target="_blank" href="/admin/studentGroups/">Группы студентов</a></li>
            <li><a target="_blank" href="/admin/logins/">Логины</a></li>
            <li><a target="_blank" href="/admin/contracts/">Договоры</a></li>
            <hr/>
            <div style={{fontSize: 30}}>База данных</div>
            <li><a target="_blank" href="/admin/statuses/">Статусы</a></li>
            <li><a target="_blank" href="/admin/students/">Студенты</a></li>
            <li><a target="_blank" href="/admin/parties/">Отряды</a></li>
            <li><a target="_blank" href="/admin/discounts/">Скидки</a></li>
            <li><a target="_blank" href="/admin/templates/">Шаблоны</a></li>
            <li><a target="_blank" href="/admin/settings/">Настройки</a></li>
            <li><a target="_blank" href="/admin/schools/">Школы</a></li>
            <li><a target="_blank" href="/admin/cabinet/">Кабинеты</a></li>
            <li><a target="_blank" href="/admin/courses/">Курсы</a></li>
            <li><a target="_blank" href="/admin/program/">Программы</a></li>
            <li><a target="_blank" href="/admin/groups/">Группы</a></li>
            <li><a target="_blank" href="/admin/teachers/">Учителя</a></li>
            <li><a target="_blank" href="/admin/schedules/">Расписание</a></li>
            <li><a target="_blank" href="/admin/holidays/">Выходные и праздники</a></li>
            <li><a target="_blank" href="/admin/payments/">Выплаты учителям</a></li>
            <li><a target="_blank" href="/admin/incomes/">Оплаты за обучение</a></li>
            <li><a target="_blank" href="/admin/reschedulings/">Переносы уроков</a></li>
            <hr/>
            <div style={{fontSize: 30}}>Автоматические поля</div>
            <li><a target="_blank" href="/admin/ratings/">Посещаемость и оценки учеников</a></li>
            <li><a target="_blank" href="/admin/journal/">Посещения учителя</a></li>
            <li><a target="_blank" href="/admin/welcomeDetails/">Детали Welcome</a></li>
            <li><a target="_blank" href="/admin/finance/">Финансы</a></li>
            <li><a target="_blank" href="/admin/implements/">Имплементации</a></li>
            <hr/>
        </ul>
        </div>
    )
}

