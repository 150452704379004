import React from "react";
import Table from "../../modules/table/table";


export default function FamiliesCreate() {
    return (
        <>
            <a href={"/admin/families/"}>Назад</a>
        <Table template={{
            collection: "families",
            title: "Семья",
            items: [
                {title: "Название", key: "name", editor: "textarea"},
                {title: "Документы", key: "documents", editor: "materials"},
                {title: "Оплатить", key: "payment", editor: "input"},
                {title: "Рекомендовать", key: "recommend", editor: "input"},
            ]
        }}/>
        </>
    )

}