import logo from "./shield.svg";
import React from "react";

export default function Logo({width=330}) {
    const w = 421;
    const h = 510;
    const rmt = 50;
    const rml = 50;
    const rw = 10;

    const t2l = 6;
    const t1t = 215;
    const t2t = 320;

    const t1f=160;
    const t2f=90;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w * 4} ${h}`} style={{background: "#fff", width, maxWidth: "100%"}}>
            <image xlinkHref={logo} width={w} height={h}/>
            <rect x={w + rml} y={rmt} width={rw} height={h - rmt * 2} stroke="#eee" fill="#eee" strokeWidth="1"/>
            <text x={w + rml * 2 - rw} y={t1t} textLength={w * 4 - (w + rml * 2 - rw)} fontSize={t1f}>FUTURE SIMPLE</text>
            <text x={w + rml * 2 - rw + t2l} y={t2t}  textLength={w * 4 - (w + rml * 2 - rw + t2l)} fontSize={t2f}>школа английского языка</text>
        </svg>
    )
}