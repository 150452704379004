import React from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import Privacy from "./client/pages/privacy/privacy";
import E404 from "./client/pages/e404/e404";
import Home from "./client/pages/home/home";
import Badge from "./admin/badge/badge";
import Flyer from "./admin/flyer/flyer";
import Brandbook from "./admin/brandbook/brandbook";
import Form from "./admin/form/form";
import Orders from "./admin/orders/orders";
import Schools from "./admin/schools/schools";
import Courses from "./admin/courses/courses";
import Settings from "./admin/settings/settings";
import Students from "./admin/students/students";
import Groups from "./admin/groups/groups";
import Teachers from "./admin/teachers/teachers";
import Program from "./admin/program/program";
import Title from "./admin/title/title";
import Face from "./admin/face/face";
import OpenClosed from "./admin/openclosed/openclosed";
import Flyermap from "./admin/flyermap/flyermap";
import Blank from "./admin/blank/blank";
import Flyermapa5 from "./admin/flyermapa5/flyermapa5";
import BlankCourses from "./admin/blank-courses/blank-courses";
import Logotype from "./admin/logotype/logotype";
import Logotype2 from "./admin/logotype2/logotype2";
import {Student} from "./student/student";
import {Auth} from "./firebase/auth";
import Schedules from "./admin/schedules/schedules";
import Cabinet from "./admin/cabinet/cabinet";
import Holidays from "./admin/holidays/holidays";
import Journal from "./admin/journal/journal";
import {Teacher} from "./teacher/teacher";
import Reschedulings from "./admin/reschedulings/reschedulings";
import Payments from "./admin/payments/payments";
import Ratings from "./admin/ratings/ratings";
import StudentGroups from "./admin/studentGroups/studentGroups";
import Incomes from "./admin/incomes/incomes";
import {Welcome} from "./welcome/welcome";
import WelcomeDetails from "./admin/welcomeDetails/welcomeDetails";
import Flyermapa7 from "./admin/flyermapa7/flyermapa7";
import Families from "./admin/families/families";
import FamiliesCreate from "./admin/families/families-create";
import Logins from "./admin/logins/logins";
import {Admin} from "./admin/admin";
import TeacherProgram from "./teacher/program";
import Templates from "./admin/templates/templates";
import {Template} from "./admin/templates/template";
import Contracts from "./admin/contracts/contracts";
import Discounts from "./admin/discounts/discounts";
import FlyerChina from "./admin/flyerchina/flyerchina";
import Parties from "./admin/parties/parties";
import Statuses from "./admin/statuses/statuses";
import Finance from "./admin/finance/finance";
import Implements from "./admin/implements/implements";
import Online from "./client/pages/online/online";
import Recommend from "./client/pages/recommend/recommend";
import Install from "./modules/install";

const AdminRouting = () => {
    return (
        <Switch>
            <Route exact path='/teacher/' component={Teacher}/>
            <Route exact path='/teacher/program/:id' component={TeacherProgram}/>
            <Route exact path='/student/' component={Student}/>
            <Route exact path='/admin/' component={Admin}/>
            <Route exact path='/admin/blank-courses/' component={BlankCourses}/>
            <Route exact path='/admin/title/' component={Title}/>
            <Route exact path='/admin/brandbook/' component={Brandbook}/>
            <Route exact path='/admin/logotype/' component={Logotype}/>
            <Route exact path='/admin/logotype2/' component={Logotype2}/>
            <Route exact path='/admin/badge/' component={Badge}/>
            <Route exact path='/admin/blank/' component={Blank}/>
            <Route exact path='/admin/face/' component={Face}/>
            <Route exact path='/admin/openclosed/' component={OpenClosed}/>
            <Route exact path='/admin/flyer/' component={Flyer}/>
            <Route exact path='/admin/flyermap/' component={Flyermap}/>
            <Route exact path='/admin/flyermapa5/' component={Flyermapa5}/>
            <Route exact path='/admin/Flyermapa7/' component={Flyermapa7}/>
            <Route exact path='/admin/flyerchina/' component={FlyerChina}/>
            <Route exact path='/admin/parties/' component={Parties}/>
            <Route exact path='/admin/contracts/' component={Contracts}/>
            <Route exact path='/admin/discounts/' component={Discounts}/>
            <Route exact path='/admin/contracts/:id' component={Contracts}/>
            <Route exact path='/admin/templates/' component={Templates}/>
            <Route exact path='/admin/template/' component={Template}/>
            <Route exact path='/admin/form/' component={Form}/>
            <Route exact path='/admin/orders/' component={Orders}/>
            <Route exact path='/admin/schools/' component={Schools}/>
            <Route exact path='/admin/courses/' component={Courses}/>
            <Route exact path='/admin/settings/' component={Settings}/>
            <Route exact path='/admin/students/' component={Students}/>
            <Route exact path='/admin/students/:id' component={Students}/>
            <Route exact path='/admin/statuses/' component={Statuses}/>
            <Route exact path='/admin/finance/' component={Finance}/>
            <Route exact path='/admin/implements/' component={Implements}/>
            <Route exact path='/admin/implements/:id' component={Implements}/>
            <Route exact path='/admin/teachers/' component={Teachers}/>
            <Route exact path='/admin/groups/' component={Groups}/>
            <Route exact path='/admin/program/' component={Program}/>
            <Route exact path='/admin/schedules/' component={Schedules}/>
            <Route exact path='/admin/schedules/:id' component={Schedules}/>
            <Route exact path='/admin/cabinet/' component={Cabinet}/>
            <Route exact path='/admin/cabinet/:id' component={Cabinet}/>
            <Route exact path='/admin/families/' component={Families}/>
            <Route exact path='/admin/families-create/' component={FamiliesCreate}/>
            <Route exact path='/admin/families/:id' component={Families}/>
            <Route exact path='/admin/holidays/' component={Holidays}/>
            <Route exact path='/admin/journal/' component={Journal}/>
            <Route exact path='/admin/payments/' component={Payments}/>
            <Route exact path='/admin/ratings/' component={Ratings}/>
            <Route exact path='/admin/incomes/' component={Incomes}/>
            <Route exact path='/admin/reschedulings/' component={Reschedulings}/>
            <Route exact path='/admin/welcomeDetails/' component={WelcomeDetails}/>
            <Route exact path='/admin/program/:id' component={Program}/>
            <Route exact path='/admin/studentGroups/' component={StudentGroups}/>
            <Route exact path='/admin/studentGroups/group/:id' component={StudentGroups}/>
            <Route exact path='/admin/studentGroups/student/:id' component={StudentGroups}/>
            <Route exact path='/admin/logins/' component={Logins}/>
            <Route path="*" component={E404} status={404}/>
        </Switch>
    );
}
const OnlineRouting = () => {
    return (
        <Switch>
            <Route exact path='/' component={Online}/>
            <Route exact path='/privacy/' component={Privacy("Мяловой Анной Михайловной", document.location.host)}/>
            <Route path="*" component={E404} status={404}/>
        </Switch>
    );
}
const Routing = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/welcome' component={Welcome}/>
            <Route exact path='/school/:schoolId/' component={Home}/>
            <Route exact path='/course/:courseId/' component={Home}/>
            <Route exact path='/school/:schoolId/course/:courseId/' component={Home}/>
            <Route exact path='/privacy/' component={Privacy("Мяловой Анной Михайловной", document.location.host)}/>
            <Route exact path='/recommend/' component={Recommend}/>
            <Route path="*" component={E404} status={404}/>
        </Switch>
    );
}

const App = () => {
    const pathname = document.location.pathname;
    const accountSubdomain = "account";
    const subDomain = (d => d.length > 2 && d[0])(document.location.host.replace("localhost", "localhost.localhost").split("."));
    const protocol = `${document.location.protocol}//`;
    const accountURL = `${protocol}${accountSubdomain}.${document.location.toString().replace(protocol, "")}`;
    const isAccount = ["admin", "teacher", "student"].includes(document.location.pathname.split("/")[1]);

    if (subDomain === "f") return document.location = `${protocol}${accountSubdomain}.${document.location.toString().replace(`${protocol}f.`, "")}`;;

    if (!subDomain && isAccount) return document.location = accountURL;
    if (subDomain === accountSubdomain) return <Auth><AdminRouting/><Install/></Auth>;
    if (subDomain === "online") return <OnlineRouting/>;
    return <Routing/>
}

export default App;
