import React from "react";
import Table from "../../modules/table/table";


export default function Schools() {
    return (
        <Table template={{
            collection: "schools",
            title: "Школы",
            items: [
                {title: "Id", key: "id", editor: "input"},
                {title: "Адрес", key: "address", editor: "input"},
                {title: "Координаты", key: "coords", editor: "input"},
                {title: "График", key: "schedules", editor: "input"},
                {title: "Процент", key: "percent", editor: "input"},
                {title: "Плюс", key: "plus", editor: "input"},
                {title: "Телефон", key: "phone", editor: "input"},
                {title: "Email", key: "email", editor: "input"},
                {title: "WhatsApp", key: "whatsapp", editor: "input"},
                {title: "Telegram", key: "telegram", editor: "input"},
                {title: "VK", key: "vk", editor: "input"},
                {title: "Tiktok", key: "tiktok", editor: "input"},
                {title: "Instagram", key: "instagram", editor: "input"},
                {title: "Facebook", key: "facebook", editor: "input"},
                {title: "Youtube", key: "youtube", editor: "input"},
            ]
        }}/>
    )

}