import React from 'react';
import Typography from "@mui/material/Typography";
import Cards from "../cards/cards";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import CallToAction from "../calltoaction/calltoaction";
import DoneIcon from '@mui/icons-material/Done';


export default function Course({course: {title, text, lines, image}}) {
    return (
        <div className="section">
            <Typography gutterBottom variant="h2">Курс «{title}»</Typography>
            <Typography gutterBottom variant="h3">{text}</Typography>
            <div className="section">
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <img src={image} alt="" width={"100%"}/>
                    </Grid>
                    <Grid item xs>
                        <List>{lines.map(line => (
                            <ListItem disableGutters={true} key={line}>
                                <ListItemIcon style={{minWidth: 35}}><DoneIcon color="primary"/></ListItemIcon>
                                <ListItemText >
                                    <div dangerouslySetInnerHTML={{__html: line}}/>
                                </ListItemText>
                            </ListItem>
                        ))}</List>
                        <div style={{marginTop: 24}}>
                            <CallToAction size="large"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Cards cards={[]}/>
        </div>
    )
}
