import React from "react";
import Table from "../../modules/table/table";
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";

const List = (props) => {
    const [list, setList] = React.useState(false);
    !list && firebase.firestore().collection("courses").get().then(snapshot => {
        const list = snapshot.docs.map(d=>d.data());
        list.sort((a, b) => (a._date || 100000) - (b._date || 100000));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));
        firebase.firestore().collection("programs").get().then(snapshot => {
            const programs = snapshot.docs.map(d=>d.data());
            list.forEach(l=>l.count = programs.filter(s=>s.course === l._id).length);

            setList(list);
        })
    });

    if(!list)return false;

    return (
        <table style={{width: "1%", whiteSpace: "nowrap"}}>
            <thead>
            <tr>
                <th>Курс</th>
                <th>Кол-во занятий</th>
            </tr>
            </thead>
            <tbody>
            {
                list.map(({id, _id, headline, count})=>(
                    <tr key={id}>
                        <td style={{padding: "4px 8px", border: "none"}}>
                            <a style={{color: _id === props.id ? "#ff0000" : "#000000"}} href={`/admin/program/${_id}/`}>
                                {headline}
                            </a>
                        </td>
                        <td style={{padding: "4px 8px", border: "none"}}>{count}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
};




export default function Program({match: {params: {id}}}) {
    return (
        <React.Fragment>
        <List id={id}/>
        {id && <Table template={{
            collection: `programs`,
            title: "Программа",
            items: [
                {title: "Курс", key: "course", editor: "hidden", db: id},
                {title: "#", key: "num", editor: "num", small: true},
                {title: "Тема", key: "topic", editor: "materials"},
                {title: "Д/З", key: "homework", editor: "materials"},
            ]
        }}/>}
        </React.Fragment>
    )

}