import React from "react";
import Table from "../../modules/table/table";


export default function Parties() {
    return (
        <>
            <Table template={{
                collection: "parties",
                title: "Отряды",
                items: [
                    {title: "Название", key: "name", editor: "input"},
                    {title: "Возраст от", key: "start", editor: "input"},
                    {title: "Возраст до", key: "end", editor: "input"},
                ]
            }}/>
            <Table template={{
                collection: "partiesCourses",
                title: "Отряд Курс",
                items: [
                    {title: "Отряд", key: "party", editor: "db", db: {collection: "parties", title: "name"}},
                    {title: "Курс", key: "course", editor: "db", db: {collection: "courses", title: "headline"}},
                ]
            }}/>
        </>
    )

}