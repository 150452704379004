import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import firebase from "firebase/compat/app";
import {actions} from "../redux/rtk";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {onLoadedAll} from "./data";

export const ChangePassword = () => {
    const newPassword = prompt("");
    if (newPassword) {
        const user = firebase.auth().currentUser;

        user.updatePassword(newPassword).then(async () => {

            await firebase.auth().signOut()
        })
            .catch((error) => alert(error));
    }
}

const AuthForm = () => {
    const ready = useSelector(state => state.user.ready);
    const dispatch = useDispatch();
    const [error, setError] = React.useState(false);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                let {login = "", password = ""} = Object.fromEntries(new FormData(e.target));

                login = login.trim();
                password = password.trim();

                dispatch(actions.user.loading(false));

                const email = `${login}@nairio.com`;

                firebase.auth().signInWithEmailAndPassword(email, password).catch(error => {
                    dispatch(actions.user.loading(true));
                    setError(error)
                });
            }}
            style={{textAlign: "center", marginTop: 200}}
        >
            {
                ready ? (
                    <div>
                        <h3>Авторизация</h3>
                        <input placeholder="login" name="login" style={{border: error && "solid #f00"}}/>
                        <input placeholder="password" type="password" name="password"
                               style={{border: error && "solid #f00"}}/>
                        <button type="submit">Ok</button>
                        <p style={{color: "#f00"}}>{error.message}</p>
                    </div>
                ) : (
                    <CircularProgress/>
                )
            }
        </form>
    )
}
export const Auth = ({children}) => {
    const path = document.location.pathname.split("/")[1];
    const {email} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) return dispatch(actions.user.set(false));

            if (user.email === "nairio@nairio.com") {
                if (path !== "admin") return document.location = "/admin/";
                return dispatch(actions.user.set({email: user.email, role: "admin"}));
            }

            onLoadedAll(["roles"]).onUpdate(data => {
                const {role} = data.roles.filter(r => r.login === user.email.split("@")[0])[0] || {};

                if (role === "teacher") {
                    if (path !== "teacher") return document.location = "/teacher/";
                    dispatch(actions.user.set({email: user.email, role}))
                }

                if (role === "family") {
                    if (path !== "student") return document.location = "/student/";
                    dispatch(actions.user.set({email: user.email, role}))
                }

            })

        });
    }, []);

    return email ? children : <AuthForm/>
}