import React, {useEffect} from "react";
import "firebase/compat/firestore";
import {onLoadedAll, roubles} from "../../firebase/data";
import Less from "../../modules/less/less";

const All = () => {
    const [items, setItems] = React.useState(false);
    useEffect(() => {
        onLoadedAll(["groups", "students", "statuses", "studentGroups", "families", "parents"])
            .onUpdate(({groups, students, statuses, studentGroups, families, parents}) => {

                statuses = statuses.filter(status => status.isActual).map(status => status._id);
                students = students.filter(student => statuses.includes(student.status));
                const studentsIds = students.map(student=>student._id);
                studentGroups = studentGroups.filter(sg => studentsIds.includes(sg.student));

                const items = studentGroups;
                items.forEach(item => {
                    item.parentData = parents.filter(parent => parent._id === item.parent)[0] || {};
                    item.studentData = students.filter(student => student._id === item.student)[0] || {};
                    item.groupData = groups.filter(group => group._id === item.group)[0] || {};
                    item.familiesData = families.filter(family => family._id === item.studentData.family)[0] || {};
                });
                items.sort((a, b) => a.groupData.name > b.groupData.name ? 1 : -1);
                items.sort((a, b) => a.studentData.name > b.studentData.name ? 1 : -1);
                setItems(items);
            })
    }, []);


    if (!items) return;

    console.log(items);

    return (
        <table border={1} style={{borderCollapse: "collapse"}}>
            <thead>
            <tr>
                <th>Студент</th>
                <th>Группа</th>
                <th>Заказчик</th>
                <th>Логин</th>
                <th>Договор</th>
            </tr>
            </thead>
            <tbody>
            {items.map((item, i) => (
                    <tr key={i}>
                        <td>{item.studentData.name}</td>
                        <td>{item.groupData.name}</td>
                        <td>{item.parentData.name}</td>
                        <td>{item.familiesData.login}</td>
                        <td>
                        {
                            item.parentData.name && item.studentData.name && item.groupData.name && item.familiesData.login ? (
                                <a target="_blank" href={`/admin/contracts/${item._id}/`}>{item._date}</a>
                            ): "----------------------"}
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

const Item = ({id}) => {
    const [contract, setContract] = React.useState(false);

    useEffect(() => {
        onLoadedAll(["passwords", "groups", "parents", "students", "templates", "executors", "studentGroups", "schools", "courses", "subjects", "families"])
            .onUpdate(({passwords, groups, parents, students, studentGroups, templates, executors, schools, courses, subjects, families}) => {
                const sg = studentGroups.filter(sg => sg._id === id)[0];
                const parent = parents.filter(parent => parent._id === sg.parent)[0];
                const student = students.filter(student => student._id === sg.student)[0];
                const family = families.filter(family => family._id === student.family)[0];
                const password = passwords.filter(password => password.login === family.login)[0];
                const group = groups.filter(group => group._id === sg.group)[0];
                const executor = executors.filter(executor => executor._id === group.executor)[0];
                const school = schools.filter(school => school._id === group.school)[0];
                const course = courses.filter(course => course._id === group.course)[0];
                const subject = subjects.filter(subject => subject._id === course.subject)[0];
                const template = templates.filter(template => template._id === group.template)[0].template;

                const price = sg.price;

                parent.consumer = student.name;

                const createContract = new Function("date", "number", "executor", "customer", "city", "subject", "course", "address", "finish", "price", "wordPrice", "account", "login", "password", `return ${template}`);

                const contract = createContract(
                    new Date().toLocaleDateString(),
                    sg._date,
                    executor,
                    parent,
                    school.address.split(",")[0],
                    subject.name,
                    course.headline,
                    school.address,
                    new Date(group.end).toLocaleDateString(),
                    price,
                    roubles(price),
                    `https://${document.location.host}/student/`,
                    password.login,
                    password.password,
                );


                setContract(contract);
            })
    }, []);

    if (!contract) return;

    const CH = ({index = 0, item}) => {
        const T = ["h1", "h2", "h3", "h4", "h5", "h6"];
        const T1 = T[index];
        return (
            <>
                <T1 dangerouslySetInnerHTML={{__html: item.title || item.title === "" ? item.title : item}}/>
                {(item.items || []).map((item, i) => (
                    <CH key={i} index={index + 1} item={item}/>
                ))}
            </>
        )
    }

    return (
        <>
            <Less file={require("./contracts.less")}>

                <div className="container">
                    <h1 dangerouslySetInnerHTML={{__html: contract.title}}/>

                    <div className="between">
                        <table>
                            <tbody>
                            <tr>
                                <td>Аккаунт:</td>
                                <td><b>{contract.account}</b></td>
                            </tr>
                            <tr>
                                <td>Логин:</td>
                                <td><b>{contract.login}</b></td>
                            </tr>
                            <tr>
                                <td>Пароль:</td>
                                <td><b>{contract.password}</b></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="between">
                        <div>{contract.city}</div>
                        <div>{contract.date}</div>
                    </div>
                    <div className="main">{contract.head}</div>
                    <CH item={{title: "", items: contract.items}}/>
                    <div className="between">
                        <div className="col">
                            <p><b>Исполнитель:</b></p>
                            {contract.requisites[0].map((r, i) => <p key={i}>{r}</p>)}
                            <div className={"mya-sign"} >
                                <img src={contract.executor.sign} alt={""}/>
                            </div>
                        </div>
                        <div className="col">
                            <p><b>Заказчик:</b></p>
                            {contract.requisites[1].map((r, i) => <p key={i}>{r}</p>)}
                        </div>
                    </div>
                    <div className="between">
                        <div className="sign col">{contract.requisites[0][0]}</div>
                        <div className="sign col">{contract.requisites[1][0]}</div>
                    </div>

                </div>

                {(contract.applications || []).map((application, i) => (
                    <React.Fragment key={i}>
                        <div className="break"/>
                        <div className="container">
                            <div className="between" style={{fontSize: 8}}>
                                <div/>
                                <div>{application.head}</div>
                            </div>
                            <h1 dangerouslySetInnerHTML={{__html: application.title}}/>
                            <CH item={{title: "", items: application.items}}/>
                            <div className="between">
                                <div/>
                                <div className="sign col">{contract.requisites[1][0]}</div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </Less>
        </>
    )
}

export default function Contracts({match: {params: {id}}}) {
    return id ? <Item id={id}/> : <All/>
}