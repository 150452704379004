import React, {useEffect, useState} from "react";
import Less from "../../modules/less/less";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {deleteFile, downloadFile, uploadFile} from "../../firebase/storage";
import {onLoadedAll} from "../../firebase/data";


const IMG = ({style, className, onChange, defaultValue}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeImage = () => {
        handleClose();

        uploadFile("images/", (src) => {
            onChange({target: {value: src}});
            defaultValue && deleteFile(defaultValue)
        })
    }
    const onRemoveImage = async () => {
        handleClose();
        await deleteFile(defaultValue);
        onChange({target: {value: ""}})
    }

    const onDownloadImage = () => {
        handleClose();
        downloadFile(defaultValue)
    }

    return (
        <div className={className} style={{...style, display: "flex", justifyContent: "center"}}>
            <div className="logo" style={{backgroundImage: `url(${defaultValue})`}} onClick={handleClick}/>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                <MenuItem onClick={onChangeImage}>Загрузить</MenuItem>
                <MenuItem onClick={onDownloadImage}>Скачать</MenuItem>
                <MenuItem onClick={onRemoveImage}>Удалить</MenuItem>
            </Menu>
        </div>
    )
}
const File = ({name, src, onRemove}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const onDownloadHandle = () => {
        handleClose();
        downloadFile(src);
    }
    const onRemoveHandle = async () => {
        handleClose();
        if (window.confirm("Точно удалить?")) {
            await deleteFile(src);
            onRemove()
        }
    }

    return (
        <div style={{
            background: "rgba(55,255,0,0.62)",
            border: "solid 1px #000000",
            margin: 4,
            cursor: "pointer",
            borderRadius: 4,
            padding: 4
        }}>
            <div onClick={handleClick}>{name}</div>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                <MenuItem onClick={onDownloadHandle}>Скачать</MenuItem>
                <MenuItem onClick={onRemoveHandle}>Удалить</MenuItem>
            </Menu>
        </div>
    )
}
const Materials = ({style, className, onChange, defaultValue}) => {
    if (typeof defaultValue === "string") defaultValue = {text: defaultValue, files: []};
    const [value, setValue] = useState(defaultValue || {text: "", files: []});

    const onFileAdd = () => {
        uploadFile("files/", (src, name) => {
            value.files.push({name, src});
            setValue(value);
            onChange({target: {value}})
        })
    }

    return (
        <div className={className} style={{display: "flex", flexDirection: "column"}}>
            <textarea rows={10} style={style} className="edit" onChange={(e) => {
                value.text = e.target.value;
                onChange({target: {value}})
            }} defaultValue={value.text}/>
            <div style={{border: "solid 1px #000000", display: "flex", flexWrap: "wrap"}}>
                {value.files.map((file, i) => (
                    <File onRemove={() => {
                        value.files.splice(i, 1);
                        onChange({target: {value}})
                    }} key={i} name={file.name} src={file.src}/>
                ))}
                <button onClick={onFileAdd}>+</button>
            </div>
        </div>
    )
}

const Sort = ({style, className, onChange, defaultValue}) => {

    const onClick = () => {
        const userInput = window.prompt("Введите индекс сортировки:", defaultValue);
        if (userInput === null) return;
        if (userInput === "") {
            onChange({target: {value: ""}})
            return;
        }
        const value = parseFloat(userInput);
        if (isNaN(value)) return;

        onChange({target: {value}})
    }

    return (
        <div onClick={onClick} style={{...style, cursor: "pointer"}} className={className} onChange={onChange}>
            {defaultValue}
        </div>
    )
}


const TBL = ({list, template: {title, collection, items}}) => {
    return (
        <Less file={require("./table.less")}>
            <h3>{title}&nbsp;<AddButton
                hiddens={items.filter(item => item.editor === "hidden").reduce((s, c) => ({...s, [c.key]: c.db}), {})}
                collection={collection}/></h3>
            <table>
                <thead>
                <tr>{items.filter(item => item.editor !== "hidden").map(({title}) => (
                    <th key={title} dangerouslySetInnerHTML={{__html: title}}/>
                ))}
                    <th>⇕</th>
                    <th>✖</th>
                </tr>
                </thead>
                <tbody>
                {
                    list.map((data, index) => (
                        <tr key={data._id} id={data._id}>
                            {items.filter(item => item.editor !== "hidden").map((item) => (
                                <EditButton width={`${100 / items.filter(i => !i.small).length}%`}
                                            key={data._id + item.key} index={index} collection={collection} item={item}
                                            data={data} small={item.small}/>
                            ))}
                            <EditButton collection={collection} item={{key: "_sort", editor: "sort"}} data={data}
                                        small/>
                            <DeleteButton collection={collection} id={data._id}/>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Less>
    )
};
const EditButton = ({index, collection, item: {key, editor, db}, data, small, width}) => {
    const [color, setColor] = React.useState("#fff");
    const onchange = (e) => {
        const value = e.target.value === "-------------" ? "" : e.target.value;
        setColor("#ff0");
        firebase.firestore().collection(collection).doc(data._id).set({
            ...data,
            [key]: value
        }).catch(() => setColor("#F00")).then(() => setColor("#0f0"))
    };

    return (
        <td style={{width: (small || editor === "image") ? "1%" : width, minWidth: 15, whiteSpace: "nowrap"}}>
            <div style={{height: "100%"}}>
                {editor === "num" && index + 1}

                {editor === "image" &&
                <IMG style={{background: color}} className="edit" onChange={onchange} defaultValue={data[key]}/>}
                {editor === "date" &&
                <input type={"date"} style={{background: color}} className="edit" onChange={onchange}
                       defaultValue={data[key]}/>}
                {editor === "password" &&
                <input type={"password"} style={{background: color}} className="edit" onChange={onchange}
                       defaultValue={data[key]}/>}
                {editor === "fbdate" && <input style={{background: color}} className="edit" onChange={onchange}
                                               defaultValue={data[key] && data[key].toDate().toLocaleString().replace(",", "")}/>}
                {editor === "time" &&
                <input type={"time"} style={{background: color}} className="edit" onChange={onchange}
                       defaultValue={data[key]}/>}
                {editor === "input" &&
                <input style={{background: color}} className="edit" onChange={onchange} defaultValue={data[key]}/>}
                {editor === "link" && <a target={"_blank"} href={`/admin/${db}/${data._id}/`}>{data._date}</a>}
                {editor === "sort" &&
                <Sort style={{background: color}} className="edit" onChange={onchange} defaultValue={data[key]}/>}
                {editor === "textarea" &&
                <textarea rows={3} style={{background: color}} className="edit" onChange={onchange}
                          defaultValue={data[key]}/>}
                {editor === "db" && <DBSelect db={db} style={{background: color}} className="edit" onChange={onchange}
                                              defaultValue={data[key]}/>}
                {editor === "select" && <Select db={db} style={{background: color}} className="edit" onChange={onchange}
                                                defaultValue={data[key]}/>}
                {editor === "materials" &&
                <Materials db={db} style={{background: color}} className="edit" onChange={onchange}
                           defaultValue={data[key]}/>}
                {editor === "schedulesGroups" &&
                <SchedulesGroups db={db} style={{background: color}} className="edit" onChange={onchange}
                                 defaultValue={data[key]}/>}
            </div>
        </td>
    )
};
const AddButton = ({collection, hiddens}) => <button onClick={() => {
    firebase.firestore().collection(collection).add({...hiddens, _date: +new Date()})
}}>+</button>;
const DeleteButton = ({collection, id}) => <td style={{width: "1%"}}><span title="Удалить"
                                                                           style={{color: "#f00", cursor: "pointer"}}
                                                                           onClick={() => window.confirm("Точно удалить?") && firebase.firestore().collection(collection).doc(id).delete()}>✖</span>
</td>;
const Progress = () => <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/></div>;

const DBSelect = (props) => {
    const {db} = props;
    const [options, setOptions] = React.useState(false);

    !options && firebase.firestore().collection(db.collection).get().then(snapshot => {
        const list = [];
        snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
        list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));

        let filteredList = list.map(l => ({title: l[db.title], value: l._id}));

        if (db.collection === "students") {
            return onLoadedAll(["students", "statuses"]).onUpdate(({students, statuses}) => {
                statuses = statuses.filter(status => status.isActual).map(status => status._id);
                students = students.filter(student => statuses.includes(student.status)).map(student => student._id);
                filteredList = filteredList.filter(item => students.includes(item.value) || !item.value);
                setOptions(filteredList);
            })
        }

        setOptions(filteredList);
    });

    if (!options) return <CircularProgress/>;

    return (
        <select {...props}>
            <option>-------------</option>
            {options.map(({title, value}) => (
                <option key={value} value={value}>{title}</option>
            ))}
        </select>
    )
};
const Select = (props) => {
    return (
        <select {...props}>
            {props.db.map(({title, value}) => <option key={value} value={value}>{title}</option>)}
        </select>
    )
};

const getGroups = (cabinet, school) => {
    if (!cabinet) return false;
    return new Promise((resolve) => {
        firebase.firestore().collection("groups").onSnapshot((snapshot) => {
            resolve(snapshot.docs.map(d => d.data()).filter(d => d.school === school));
        })
    })
}
const getStudents = () => {
    return new Promise((resolve) => {
        onLoadedAll(["students", "statuses", "studentGroups"]).onUpdate(({students, statuses, studentGroups}) => {
            statuses = statuses.filter(status => status.isActual).map(status => status._id);
            students = students.filter(student => statuses.includes(student.status));
            students.forEach(s => s.groups = studentGroups.filter(sg => sg.student === s._id).map(g => g.group))
            resolve(students);
        })
    })
}

const SchedulesGroups = (props) => {
    const [options, setOptions] = React.useState(false);

    const {db: {cabinet, school}} = props;

    (async () => {
        const groups = await getGroups(cabinet, school);
        const students = await getStudents();
        setOptions(groups.map(g => ({
            title: g.name,
            value: g._id,
            count: students.filter(s => s.groups.includes(g._id)).length
        })))
    })()


    if (!options) return <CircularProgress/>;

    return (
        <select {...props}>
            <option>-------------</option>
            {
                options.map(({title, value, count}) => (
                    <option key={value} value={value}>
                        {count} | {title}
                    </option>
                ))
            }
        </select>
    )
};


export default function Table({template}) {
    template.items.forEach(item => item.db = item.db || "");
    const [list, setList] = React.useState(false);

    useEffect(() => {
        firebase.firestore().collection(template.collection).onSnapshot((snapshot) => {
            const filters = template.items.filter(item => item.editor === "hidden");

            const list = [];
            snapshot.forEach((s) => (d => list.push({...d, _id: s.id}))(s.data()));
            list.sort((a, b) => (a._date || 100000) - (b._date || 100000));
            list.sort((a, b) => (a._sort || 100000) - (b._sort || 100000));

            let filteredList = list;
            filters.forEach(({key, db}) => {
                filteredList = filteredList.filter(item => item[key] === db)
            });

            if (template.collection === "studentGroups") {
                return onLoadedAll(["students", "statuses"]).onUpdate(({students, statuses}) => {
                    statuses = statuses.filter(status => status.isActual).map(status => status._id);
                    students = students.filter(student => statuses.includes(student.status)).map(student => student._id);
                    filteredList = filteredList.filter(item => students.includes(item.student) || !item.student);
                    setList(filteredList);
                })
            }


            setList(filteredList);
        })
    }, [])


    if (!list) return <Progress/>

    return (
        <TBL list={list} template={template}/>
    )

}