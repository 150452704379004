import Logo from "../../logo/logo";
import React from "react";
import Roboto from "../../Roboto-Bold.ttf";
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import PlaceIcon from '@mui/icons-material/Place';


export default class Blank extends React.Component{
    constructor(props) {
        super(props);
        this.state = {width: 200, height: 25, text: "Школа английского языка", fontFamily: "Roboto"};
    }

    componentDidMount() {
        (async ()=>{
            const font = new FontFace(this.state.fontFamily, `url("${Roboto}")`);
            await font.load();
            document.fonts.add(font);
        })()
    }

    render() {
        const s = {display: "flex", marginTop: 10, fontSize: 12, marginLeft: 20, marginRight: 20};
        const d = {color: "grey", marginRight: 4, fontSize: 12};

        return (
            < >
                <div style={{margin: 30}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{width: "30%"}}>
                            <Logo width={"100%"}/>
                        </div>

                        <div style={{display: "flex", justifyContent: "center",  width: "100%"}}>
                            <div style={s}><PhoneIcon style={d}/>+7 495 142-91-57</div>
                            <div style={s}><LanguageIcon style={d}/>FutureSimple.club</div>
                            <div style={s}><PlaceIcon style={d}/>Марьинский бульвар, 10</div>
                        </div>
                        <div><img alt={""} width={40} src="/brandbook/qr-fs-club.gif?1"/></div>
                    </div>
                    <hr style={{width: "100%", border: "solid 1px #eee"}}/>
                    {this.props.children}
                </div>
            </>
        )
    }





}