import Logo from "../../logo/logo";
import React from "react";

const pix = v => v * 11.808988764044943820224719101124;
//const photo10_15 = {width: 102, height: 152};
const photo9_13 = {width: 89, height: 127};
const badge = {width: 90, height: 55};

const B = () => (
    <div style={{border: "solid 1px #eee", width: pix(badge.width), height: pix(badge.height), display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Logo width={"90%"}/>
    </div>
);

export default function Badge() {
    const {width, height} = photo9_13;
    return (
        <>
            <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <div style={{border: "solid 1px #eee", width: pix(width), height: pix(height), display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                    <B/>
                    <B/>
                </div>
            </div>
        </>
    )
}