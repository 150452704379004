import React from "react";
import Table from "../../modules/table/table";


export default function Settings() {
    return (
        <Table template={{
            collection: "settings",
            title: "Настройки",
            items: [
                {title: "Название", key: "headline", editor: "input"},
                {title: "Описание", key: "tagline", editor: "input"},
                {title: "Сайт", key: "site", editor: "input"},
                {title: "Телефон", key: "phone", editor: "input"},
                {title: "Email", key: "email", editor: "input"},
                {title: "WhatsApp", key: "whatsapp", editor: "input"},
                {title: "Telegram", key: "telegram", editor: "input"},
                {title: "VK", key: "vk", editor: "input"},
                {title: "Tiktok", key: "tiktok", editor: "input"},
                {title: "Instagram", key: "instagram", editor: "input"},
                {title: "Facebook", key: "facebook", editor: "input"},
                {title: "Youtube", key: "youtube", editor: "input"},
                {title: "Line 1", key: "line1", editor: "input"},
                {title: "Line 2", key: "line2", editor: "input"},
                {title: "Line 3", key: "line3", editor: "input"},
                {title: "Line 4", key: "line4", editor: "input"},
                {title: "Line 5", key: "line5", editor: "input"},
                {title: "Line 6", key: "line6", editor: "input"},
                {title: "Онлайн", key: "online", editor: "materials"},
            ]
        }}/>
    )

}