import {roubles} from "../../firebase/data";

const date = new Date().toLocaleDateString();
const number = "1";
const city = "город Москва";
const subject = "английскому языку";
const course = "Английский язык для подростков «Gateway A1+» 11-14 лет";
const address = "Марьинский бульвар, 10";
const finish = "31 августа 2021 года";
const price = "7234";
const wordPrice = roubles(price);
const account = "https://futuresimple.club/student/";
const login = "";
const password = "";

const executor = {
    name: "Индивидуальный предприниматель Мялова Анна Михайловна",
    INN: "010514271911",
    OGRN: "31950740044430",
    bank: "Р/C: 40802810002500108448 БИК: 044525999 ПАО БАНК 'ФК ОТКРЫТИЕ' г.Москва, К/C: 30101810845250000999",
    base: "свидетельства о государственной регистрации в качестве индивидуального предпринимателя №530391702, выданного 13.09.2019 Межрайонной инспекцией Федеральной Налоговой службы №5 по Московской области",
    address: "142108, Московская область, г.Подольск, ул.Бородинская, 17 а кв.75",
    phone: "+7 (929) 610-97-40"
}
const customer = {
    name: "",
    passport: "",
    address: "",
    phone: "",
    consumer: ""
};
const template = {
    title: `Договор № ${number}<br/>об оказании платных образовательных услуг`, city, date, login, password,account,
    head: `${executor.name} (в дальнейшем – Исполнитель), действующий на основании ${executor.base}, с одной стороны и ${customer.name}, далее именуемый(ая) Заказчик, с другой стороны, и ${customer.consumer}, далее именуемый(ая) Потребитель, с третьей стороны, при совместном упоминании именуемые Стороны, а по отдельности - Сторона, заключили настоящий договор (далее – договор) о нижеследующем:`,
    requisites: [
        [
            `${executor.abbreviated}`,
            `ИНН: ${executor.INN} ОГРН: ${executor.OGRN}`,
            `${executor.bank}`,
            `Адрес: ${executor.address}`,
            `Тел.: ${executor.phone}`,
        ],
        [
            `${customer.name}`,
            `Паспорт: ${customer.passport}`,
            `Адрес: ${customer.address}`,
            `Тел.: ${customer.phone}`
        ],
    ],
    items: [
        {
            title: "Общие положения.", items: [
                `С момента заключения договора Исполнитель оказывает, а Заказчик оплачивает образовательные услуги (далее – Услуги) по изучению предмета "${subject}", оказываемые Исполнителем Потребителю индивидуально либо в группах в соответствии со следующей дополнительной образовательной Программой (курсом): ${course}, выбранной Заказчиком (далее по тексту – «Программа»), и на прочих условиях, предусмотренных Договором.`,
                `Услуги организуются и проводятся Исполнителем по адресу: ${address}`,
            ]
        },
        {
            title: "Сроки и порядок действия договора.", items: [
                "Договор становится действительным с момента его подписания.",
                `Настоящий Договор вступает в силу с момента его подписания Сторонами и действует до ${finish}. Каждая Сторона вправе в одностороннем порядке отказаться от исполнения настоящего Договора, уведомив об этом другую Сторону не менее чем за 1 месяц.`,
                "Договор составлен в двух экземплярах на русском языке. Оба экземпляра идентичны и имеют одинаковую силу. У каждой из сторон находится один экземпляр настоящего договора.",
            ]
        },
        {
            title: "Права Сторон.", items: [
                {
                    title: "Исполнитель вправе:", items: [
                        "Самостоятельно осуществлять процесс оказания Услуг, выбирать методику преподавания, формы, порядок и периодичность тестирования.",
                        "Направлять Заказчику уведомления о не допуске Потребителя к занятиям, как по мотивам неоплаты услуг Исполнителя, так и по иным основаниям. Уведомления направляются по указанному в анкете настоящего Договора e-mail адресу и/или телефону.",
                        "Не допускать Потребителя к занятиям в случае неисполнения или просрочки исполнения Заказчиком обязательства по оплате Услуг до даты исполнения Заказчиком обязательств по оплате.",
                        "Уведомить Заказчика о нецелесообразности оказания Потребителю образовательных услуг в объеме, предусмотренном настоящим Договором вследствие нарушения правил поведения на занятиях и правил посещаемости занятий.",
                        "Исполнитель имеет право использовать материалы, созданные Потребителем в процессе обучения в порядке и на условиях, предусмотренных Приложением №2 к настоящему договору.",
                    ]
                },
                {
                    title: "Заказчик вправе:", items: [
                        "Требовать от Исполнителя предоставления информации по вопросам организации и обеспечения надлежащего исполнения услуг.",
                        "Обращаться к Исполнителю и его представителям по вопросам, касающимся процесса оказания услуг.",
                        "Пользоваться имуществом Исполнителя, необходимым для осуществления процесса оказания услуг.",
                        "В случаях, предусмотренных действующим законодательством и настоящим Договором – расторгнуть Договор.",
                    ]
                },
            ]
        },
        {
            title: "Обязанности Сторон.", items: [
                {
                    title: "Исполнитель обязуется:", items: [
                        "Включить Потребителя в ближайшую формируемую группу. По окончании формирования группы начать оказание услуг по освоению образовательной Программы. Во избежание сомнениий, Стороны пришли к соглашению, что надлежащим информированием Потребителя о начале занятий будет являться: уведомление по указанному в анкете настоящего Договора e-mail адресу и/или телефону. Исполнитель не несет ответственности за неверно указанные Заказчиком реквизиты анкете Договора и за не ознакомление Потребителя с доставленными ему уведомлениями о начале обучения.",
                        "Организовать и обеспечить надлежащее оказание услуг, предусмотренных разделом 1 настоящего Договора.",
                        "Создать Потребителю необходимые условия для получения им Услуг, обеспечить для проведения занятий помещения, соответствующие санитарным и гигиеническим требованиям, а также оснащение, соответствующее обязательным нормам и правилам, предъявляемым к образовательному процессу.",
                        "Проявлять уважение к личности Потребителя, не допускать физического и психологического насилия по отношению к Потребителю, обеспечить условия эмоционального благополучия Потребителю с учетом его индивидуальных особенностей.",
                    ]
                },
                {
                    title: "Заказчик обязуется:", items: [
                        "Оплачивать услуги Исполнителя в порядке и в сроки, установленные настоящим Договором.",
                        "Нести ответственность за посещение Потребителем занятий в соответствие с согласованным графиком.",
                        "Нести ответственность за выполнение Потребителем заданий по подготовке к занятиям, задаваемые Потребителю Исполнителем и его представителями.",
                        "В случае пропуска занятий по уважительным причинам, указанным в настоящем пункте Договора, Заказчик обязан заранее (не позднее чем за 24 часа до даты и времени проведения очередного занятия) уведомлять Исполнителя по номерам телефонов и/или адресам электронной почты, указанным в настоящем Договоре, о предстоящем пропуске занятий (занятия) с указанием причины и предположительного срока пропуска, с обязательным последующим предоставлением Исполнителем документов, подтверждающих уважительность пропуска(ов) причин, - далее «надлежащее уведомление».",
                        "Занятие (занятия) будут считаться отмененными/пропущенными по уважительной причине исключительно при надлежащем уведомлении.",
                        "При первой возможности, но в любом случае не позднее чем в первый день возобновления посещения занятий, подавать письменное собственноручное заявление Исполнителю с указанием причин пропуска и с приложением документов (надлежащим образом заверенных копий документов), подтверждающих указанные причины (справка врача,). В случае не поступления Исполнителю указанного письменного заявления Заказчика с приложением указанных выше документов, пропуск в бесспорном порядке признается Сторонами произошедшим по вине Заказчика.",
                        "В случае пропуска по уважительной причине, Заказчику предоставляется  возможность компенсировать пропущенное занятие путем онлайн отработки. Заказчик обязуется своевременно согласовать с Исполнителем  дату и время проведения заменяющего занятия в режиме онлайн. Отработка пропущенного занятия осуществляется с соблюдением всех необходимых требований и материалов, предоставленных в рамках основного занятия.",
                        "Нести ответственность за соблюдение Потребителем требований Правил внутреннего распорядка Исполнителя, установленных в Приложении №1 к настоящему Договору, иных локальных нормативных актов, общепринятых норм поведения, а также за проявление уважения к другим Потребителям.",
                        "Нести ответственность за бережное отношение Потребителя к имуществу Исполнителя. Возмещать ущерб, причиненный Потребителем имуществу Исполнителя, в соответствие с законодательством Российской Федерации.",
                        "В случае, если Заказчик является законным представителем несовершеннолетнего Потребителя он несет ответственность за жизнь и здоровье Потребителя в процессе оказания Услуг, за исключением случаев, когда вред жизни или здоровью Потребителя причинен виновными действия Исполнителя. Стороны согласовали, что Потребитель самостоятельно посещает занятия Исполнителя и Исполнитель не несет обязанности по передаче несовершеннолетнего Потребителя непосредственно Заказчику.",
                        "В случае выявления заболевания Потребителя (по заключению учреждений здравоохранения) освободить Потребителя от занятий."
                    ]
                },
            ]
        },
        {
            title: "Стоимость и порядок оплаты.", items: [
                `Стоимость услуг Исполнителя составляет ${price}₽ (${wordPrice}) в месяц`,
                `Заказчик оплачивает стоимость Услуг путем перечисления Заказчиком денежных средств в валюте Российской Федерации (рубль) не позднее 5-го числа месяца в личном кабинете по адресу: ${account}`,
                "В случае пропуска Потребителем занятий по его вине, в том числе занятий в группе, оплата за Услуги Заказчику не возвращается.",
                "Расторжение Договора не освобождает Стороны от взаиморасчетов за оказанные Услуги и понесенные затраты.",
                "При досрочном расторжении Договора по инициативе Заказчика оплата, произведенная Заказчиком за текущий оплаченный Заказчиком период оказания услуг, во время которого был расторгнут Договор, Заказчику не возвращается, Услуги считаются оказанными Исполнителем Заказчику в текущем оплаченном периоде, а Потребитель вправе посетить в текущем оплаченном Заказчиком периоде все занятия согласно утвержденного графика. Текущим периодом считается текущий календарный месяц.",
                "При досрочном расторжении Договора по инициативе Заказчика все предоставленные учебные материалы подлежат возврату Исполнителю.",
                "При этом стороны согласовали, что в связи с положениями ст.782 ГК РФ суммы, указанные в п.5.1 Договора, являются компенсацией фактически понесенных расходов Исполнителя по оплате заработной плате сотрудников Исполнителя, иных расходов, связанных с исполнением Исполнителем обязательство по настоящему Договору.",
            ]
        },
        {
            title: "Расторжение Договора.", items: [
                "Заказчик вправе расторгнуть Договор при условии письменного уведомления Исполнителя не менее чем за 30 (Тридцать) дней до предполагаемой даты расторжения Договора.",
                {
                    title: "Исполнитель вправе в течение 3-х (Трех) календарных дней с момента уведомления Заказчика расторгнуть Договор в одностороннем порядке в следующих случаях:",
                    items: [
                        "при небрежном отношении Потребителя к имуществу Исполнителя и помещениям, используемым в процессе оказания услуг;",
                        "при дисциплинарных нарушениях Потребителя, влияющих на нормальный ход оказания Исполнителем услуг и приводящим к жалобам со стороны представителей Исполнителя и других Потребителей;",
                        "при невыполнении Заказчиком условий настоящего Договора."
                    ]
                },
                "В случаях, не указанных в настоящем Договоре, Договор может быть расторгнут по соглашению Сторон.",
            ]
        },
        {
            title: "Ответственность Сторон.", items: [
                "В случае неисполнения или ненадлежащего исполнения условий настоящего Договора, Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации.",
            ]
        },
        {
            title: "Порядок рассмотрения споров.", items: [
                "Все претензии, возникшие у Заказчика в связи с исполнением настоящего Договора, оформляются в письменной форме и направляются Исполнителю. Срок ответа на претензию – 14 (Четырнадцать) календарных дней с даты получения претензии Исполнителем.",
                "В случае неисполнения или ненадлежащего исполнения Заказчиком обязательств по настоящему Договору, Исполнитель вправе для разрешения спора обратиться в суд в соответствии с действующим законодательством.",
            ]
        },
        {
            title: "Обстоятельства непреодолимой силы.", items: [
                "Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств по настоящему договору, если такое неисполнение или ненадлежащее исполнение было вызвано событиями, которые независимы от воли сторон и которые не могла избежать добросовестная сторона. К таким событиям относятся: изменение или издание новых законов, постановления Правительства или местных органов власти и управления, война и военные действия, мобилизация, забастовки, пожары, взрывы и природные катастрофы, эпидемии и эпизоотии, а также другие события, которые Торгово-Промышленная Палата признает случаями непреодолимой силы.",
                "В случае если такие обстоятельства продолжаются более 2-х месяцев, каждая из сторон имеет право отказаться от дальнейшего исполнения обязательств по Договору. В этом случае Договор считается расторгнутым, и ни одна из Сторон не будет иметь права требовать возмещения убытков от другой стороны.",
                "Сторона, которая вследствие наступления обстоятельств непреодолимой силы не в состоянии выполнить свои договорные обязательства, должна незамедлительно известить другую Сторону об их наступлении и прекращении. Не уведомление об этих обстоятельствах лишает Сторону права ссылаться на них при нарушении обязательств по настоящему Договору.",
            ]
        },
        {
            title: "Правила использования и обработки персональных данных.", items: [
                "При заключении настоящего договора Заказчик дает Исполнителю свое согласие на использование и обработку персональных данных своих и Потребителя на условиях, предусмотренных в Приложении №2 к настоящему договору.",
            ]
        },
    ],
    applications: [
        {
            head: `Приложение №1 к Договору № ${number} об оказании платных образовательных услуг`,
            title: "Правила внутреннего распорядка Исполнителя",
            items : [
                {
                    title: "Потребители обязаны:", items: [
                        "соблюдать положения Договора, бережно относиться к имуществу, уважать честь и достоинство других Потребителей и работников Исполнителя;",
                        "соблюдать расписание занятий, не опаздывать и не пропускать занятия без уважительной причины;",
                        "вести себя достойно, воздерживаться от действий, мешающих другим Потребителям;",
                        "в полной мере пользоваться услугами Исполнителя, соблюдать учебную дисциплину, своевременно и точно исполнять распоряжения Исполнителя, соблюдать требования по обеспечению безопасности в помещениях Исполнителя;",
                        "беречь здание, оборудование, имущество;",
                        "бережно относится к результатам труда других Потребителей;",
                        "соблюдать порядок и чистоту в туалетах и других местах общего пользования;",
                        "соблюдать требования техники безопасности, санитарии и гигиены, правила пожарной безопасности;",
                        "в случае экстренной ситуации, связанной с обнаружением любой опасности жизни и здоровью, незамедлительно сообщить об этом любому сотруднику Исполнителя.",
                    ]
                },
                {
                    title: "Потребителям запрещается:", items: [
                        "приносить, передавать и использовать оружие, колющие и режущие предметы, боеприпасы, взрывчатые вещества, пиротехнические игрушки, а также другие предметы, подвергающие опасности жизнь и здоровье других людей;",
                        "приносить, передавать и употреблять спиртные напитки, средства токсического и наркотического опьянения, табачные изделия, находиться в помещениях Исполнителя в состоянии алкогольного, токсического или наркотического опьянения, курить на территории Исполнителя;",
                        "применять физическую силу для выяснения отношений, использовать запугивание, вымогательство;",
                        "совершать любые действия, влекущие за собой опасные последствия для окружающих, такие как толкание, удары любыми предметами, бросание чем-либо и т.д.;",
                        "играть в азартные игры (например, карты и т.п.);",
                        "находиться в помещениях Исполнителя в верхней одежде;",
                        "пользоваться во время занятий средствами мобильной связи (телефонами, планшетами и т.п.);",
                        "употреблять пищу и напитки во время оказания услуг Исполнителем;",
                        "приводить или приносить в помещения Исполнителя животных;",
                        "громко разговаривать и шуметь во время занятий;",
                    ]
                }
            ]
        },
        {
            head: `Приложение №2 к Договору № ${number} об оказании платных образовательных услуг`,
            title: "Согласие на обработку персональных данных",
            items : [
                {
                    title: "Настоящим я даю свое согласие Исполнителю, на обработку своих персональных данных и персональных данных моего несовершеннолетнего ребенка, на следующих условиях:", items: [
                        "Данное согласие дается на смешанную обработку персональных данных, как без использования средств автоматизации, так и с их использованием.",
                        "Перечень персональных данных, на обработку которых дается согласие: фамилия, имя, отчество, паспортные данные или данные документа, удостоверяющего личность, дата рождения, место рождения, номер телефона (стационарный домашний, мобильный), адрес электронной почты (e-mail), знание иностранных языков, состояние здоровья",
                        "В случае необходимости предоставления дополнительных персональных данных, не указанных в настоящем согласии, я предоставлю новое согласие при заполнении соответствующих документов.",
                        "Цель обработки персональных данных: исполнение условий заключенного со мной договора",
                        "В ходе обработки с персональными данными будут совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу, в том числе трансграничную (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.",
                        "Я осведомлен и согласен с тем, что в случае получения от меня отзыва согласия или достижения целей обработки моих персональных данных, Исполнитель обязан прекратить обработку моих персональных данных или обеспечить ее прекращение (если обработка персональных данных осуществляется лицом, действующим по поручению Исполнителя и указанным в настоящем Согласии) и уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных данных осуществляется лицом, действующим по поручению Исполнителя и указанным в настоящем Согласии) в срок, не превышающий 30 (тридцати) дней с даты получения от меня отзыва или достижения целей обработки персональных данных.",
                        "Я осведомлен, что отзыв моего Согласия может быть осуществлен путем подачи Исполнителю соответствующего заявления в письменной форме при личном обращении.",
                        "Я осведомлен, что Исполнитель имеет право использовать материалы, созданные Потребителями в процессе обучения для образовательных, рекламных и иных целей, а также публиковать их. В понятие материалы в настоящей статье включается аудио, видео и фотоизображение с участием Потребителя; работы Потребителя, выполненные в процессе обучения - тексты, рисунки, тесты и иные материалы, созданные Потребителем в процессе обучения. Исполнитель имеет право передавать материалы, указанные в настоящей статье, третьим лицам.",
                        "Заказчик дает свое согласие на то, что не будет иметь прав на фотографии, видеозаписи и иные материалы, созданные Потребителем или с участием Потребителя в процессе обучения. Все права на вышеуказанные материалы, включая авторские, принадлежат Исполнителю и его правопреемникам.",
                        "Заказчик подтверждает, что данная передача прав окончательна, имеет всемирную силу и неограниченный срок действия.",
                    ]
                },
            ]
        },
    ]
}

export const Template = () =>{
    return JSON.stringify(template)
}

