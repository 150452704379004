import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";


export const LifePayWidget = ({data: {name, cost, on_success, on_fail}}) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const s = document.head.getElementsByClassName("lifepay_script")[0];
        if (!s) {
            const s = document.createElement("script");
            s.className = "lifepay_script";
            s.onload = () => s.setAttribute("loaded", "true");
            document.head.appendChild(s);
            s.type = "text/javascript";
            s.src = "https://partner.life-pay.ru/gui/lifepay_widget/js/lp-widget.js?1234567";
        }
    }, []);

    const OnClick = (e) => {
        e.preventDefault();
        setLoading(true);
        const s = document.head.getElementsByClassName("lifepay_script")[0];
        const interval = setInterval(() => {
            if (s.getAttribute("loaded") === "true") {
                clearInterval(interval);
                let canSuccess = true;
                let canFail = true;
                let canClose = true;
                const widget = new window.LpWidget({
                    key: "czfB5RcgiaxgiprgzH+TeQNWuM+B9rXFhb8+s5xTcsE=",
                    order_id: "0",
                    name,
                    cost,
                    on_success: (e) => {if(canSuccess) {setLoading(false); canSuccess = false; on_success(e)}},
                    on_fail: (e) => {if(canFail) {setLoading(false); canFail = false; on_fail(e)}},
                    on_close: (e) => {if(canClose) {setLoading(false); canClose = false;}},
                });
                widget.render();
            }
        });
    }

    return (
        <>
            {loading && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", top: 0, left: 0, position: "absolute", width: "100%", height: "100%", background: "#00000088"}}>
                    <CircularProgress/>
                </div>
            )}
            <a className="lifepay_button" href="#" onClick={OnClick}>
                <img
                    alt=""
                    id="a1lite_button"
                    style={{border: 0}}
                    src="https://partner.life-pay.ru/gui/images/a1lite_buttons/button_small.png"
                    title="Оплатить"
                />
            </a>
        </>
    )

}
