import React from "react";
import Table from "../../modules/table/table";


export default function Statuses() {

    return (
        <Table template={{
            collection: "statuses",
            title: "Статусы",
            items: [
                {title: "Название", key: "name", editor: "input"},
                {
                    title: "Актуальный",
                    key: "isActual",
                    editor: "select",
                    db: [{title: "-----", value: ""}, {title: "Да", value: "true"}]
                },
                {
                    title: "Есть договор на этот год",
                    key: "hasContract",
                    editor: "select",
                    db: [{title: "-----", value: ""}, {title: "Да", value: "true"}]
                },
            ]
        }}/>
    )

}