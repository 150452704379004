import React from "react";
import Table from "../../modules/table/table";


export default function Templates() {
    return (
        <>
            <Table template={{
                collection: "executors",
                title: "Исполнители",
                items: [
                    {title: "Название", key: "name", editor: "textarea"},
                    {title: "Сокращенное название", key: "abbreviated", editor: "textarea"},
                    {title: "ИНН", key: "INN", editor: "textarea"},
                    {title: "ОГРН", key: "OGRN", editor: "textarea"},
                    {title: "Банк", key: "bank", editor: "textarea"},
                    {title: "На основании", key: "base", editor: "textarea"},
                    {title: "Адрес", key: "address", editor: "textarea"},
                    {title: "Телефон", key: "phone", editor: "textarea"},
                    {title: "Подпись", key: "sign", editor: "image"},
                ]
            }}/>
            <hr/>
            <Table template={{
                collection: "templates",
                title: "Шаблоны",
                items: [
                    {title: "Название", key: "name", editor: "input"},
                    {title: "Шаблон", key: "template", editor: "textarea"},
                ]
            }}/>

            <hr/>
            <Table template={{
                collection: "subjects",
                title: "Предметы",
                items: [
                    {title: "Название", key: "name", editor: "input"},
                ]
            }}/>
        </>

    )
}