function Add(html) {
    const div = document.body.appendChild(document.createElement("div"));
    div.innerHTML = html;
    const scripts = div.getElementsByTagName("script");

    for (let i = 0; i < scripts.length; i++) {
        const script = document.body.appendChild(document.createElement("script"));
        const attributes = scripts[i].attributes;

        for (let j = 0; j < attributes.length; j++) {
            script.setAttribute(attributes[j].name, attributes[j].value);
        }
        scripts[i].innerHTML && (script.innerHTML = scripts[i].innerHTML);
    }
}


export default function Postscript() {
    window.addEventListener("load",()=>{
        Add(`
    <!-- Yandex.Metrika counter -->
    <script type="text/javascript" >
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    
        ym(67304440, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    });
    </script>
    <noscript><div><img src="https://mc.yandex.ru/watch/67304440" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
    <!-- /Yandex.Metrika counter -->
`);

        Add(`
    <!-- Facebook Pixel Code -->
    <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '3963663970313935');
        fbq('track', 'PageView');
    </script>
    <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=3963663970313935&ev=PageView&noscript=1"
        /></noscript>
    <!-- End Facebook Pixel Code -->
`);

        Add(`
    <!-- Load Facebook SDK for JavaScript -->
    <div id="fb-root"></div>
    <script>
    window.fbAsyncInit = function() {
        FB.init({
            xfbml            : true,
            version          : 'v8.0'
        });
    };
    
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));</script>
    
    <!-- Your Chat Plugin code -->
    <div class="fb-customerchat" attribution=setup_tool page_id="101210448359722" logged_in_greeting="Добро пожаловать" logged_out_greeting="Добро пожаловать"></div>
`);
    });

    return false
}