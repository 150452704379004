import React, {useEffect, useState} from "react";
import {getData} from "../firebase/data";
import {StudentsView} from "./view";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";
import firebase from "firebase/compat/app";

export const Student = () => {
    const {email, role} = useSelector(state => state.user);
    const [data, setData] = useState({});

    useEffect(() => getData(email, setData, role), []);

    if (!data.ready) return <div style={{textAlign: "center", marginTop: 200}}><CircularProgress/></div>;
    if (!data.family) return <button onClick={() => firebase.auth().signOut()}>Выйти</button>


    return (
        <StudentsView family={data.family}/>
    )
}



