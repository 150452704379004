import React, {useEffect, useState} from "react";
import Table from "../../modules/table/table";
import firebase from "firebase/compat/app";
import {onLoadedAll} from "../../firebase/data";

const List1 = (props) => {
    const [list, setList] = React.useState(false);

    useEffect(() => {
        onLoadedAll(["groups", "parents", "students", "families", "statuses" ,"studentGroups"]).onUpdate(({groups, studentGroups, parents, students, families, statuses})=>{
            statuses = statuses.filter(status => status.isActual).map(status => status._id);
            students = students.filter(student => statuses.includes(student.status)).map(student=>student._id);

            const list = groups;
            const sg = studentGroups;
            list.forEach(l => l.count = sg.filter(s => s.group === l._id && students.includes(s.student)).length);
            setList(list);
        })

    }, [])

    if (!list) return false;

    return (
        <table style={{width: "1%", whiteSpace: "nowrap"}}>
            <tbody>
            {
                list.map(({_id, name, count}) => (
                    <tr key={_id}>
                        <td style={{padding: "4px 8px", border: "none"}}>
                            <a style={{color: _id === props._id ? "#ff0000" : "#000000"}}
                               href={`/admin/studentGroups/group/${_id}/`}>
                                {name}
                            </a>
                        </td>
                        <td style={{padding: "4px 8px", border: "none"}}>{count}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>

    )
};
const List2 = (props) => {
    const [list, setList] = React.useState(false);

    useEffect(() => {
        onLoadedAll(["parents", "students", "families", "statuses" ,"studentGroups"]).onUpdate(({studentGroups, parents, students, families, statuses})=>{
            statuses = statuses.filter(status => status.isActual).map(status => status._id);
            students = students.filter(student => statuses.includes(student.status));

            const list = students;
            const sg = studentGroups;
            list.forEach(l => l.count = sg.filter(s => s.student === l._id).length);
            setList(list);
        })

    }, [])

    if (!list) return false;

    return (
        <table style={{width: "1%", whiteSpace: "nowrap"}}>
            <tbody>
            {
                list.map(({_id, name, count}) => (
                    <tr key={_id}>
                        <td style={{padding: "4px 8px", border: "none"}}>
                            <a style={{color: _id === props._id ? "#ff0000" : "#000000"}}
                               href={`/admin/studentGroups/student/${_id}/`}>
                                {name}
                            </a>
                        </td>
                        <td style={{padding: "4px 8px", border: "none"}}>{count}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
};

const StudentGroups1 = ({id}) => {
    return (
        <>
            <List1 _id={id}/>
            {id && (
                <Table template={{
                    collection: "studentGroups",
                    title: "Группы студентов",
                    items: [
                        {title: "Студент", key: "student", editor: "db", db: {collection: "students", title: "name"}},
                        {title: "Группа", key: "group", editor: "hidden", db: id},
                        {title: "Сумма в месяц в договор", key: "price", editor: "input"},
                    ]
                }}/>
            )}
        </>
    )
}

const StudentGroups2 = ({id}) => {
    const [parents, setParents] = useState([]);
    useEffect(()=>{
        id && onLoadedAll(["parents", "students", "families", "statuses"]).onUpdate(({parents, students, families, statuses})=>{
            const student = students.filter(s => s._id === id)[0];
            parents = parents.filter(parent => parent.family === student.family).map(parent => ({title: parent.name, value: parent._id}));
            setParents(parents);
        })
    }, [])



    return (
        <>
            <List2 _id={id}/>

            {id && (
                <Table template={{
                    collection: "studentGroups",
                    title: "Группы студентов",
                    items: [
                        {title: "Студент", key: "student", editor: "hidden", db: id},
                        {title: "Заказчик", key: "parent", editor: "select", db: [{title: "-----", value: ""}, ...parents]},
                        {title: "Группа", key: "group", editor: "db", db: {collection: "groups", title: "name"}},
                        {title: "Сумма в месяц в договор", key: "price", editor: "input"},
                    ]
                }}/>
            )}
        </>
    )
}

export default function StudentGroups({match: {params: {id}}}) {
    const type = document.location.pathname.split("/")[3];

    return (
        <>
            {
                id && (
                    <a href={"/admin/studentGroups/"}>Назад</a>
                )
            }
            {
                (id && type==="group" || !id) && (
                    <>
                        <h3>Группы</h3>
                        <StudentGroups1 id={id}/>
                    </>
                )
            }
            {
                (id && type==="student" || !id) && (
                    <>
                        <h3>Студенты</h3>
                        <StudentGroups2 id={id}/>
                    </>
                )
            }


        </>
    )

}